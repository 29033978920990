import React, { useEffect, useRef, useState } from 'react'
import {
    Label
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    message,
    Upload
} from 'antd';

import api from '../../../services/api';
import image from '../../../assets/images/imagebg.png';
import { Link, useNavigate } from "react-router-dom";
import { useParams } from 'react-router';

const { TextArea } = Input;

const CursoView = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [imageFile,setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(image);
    const [loading,setloading] = useState(true);
    const [instrutores,set_instrutores] = useState([]);
    const [categorias,set_categorias] = useState([]);

    const disabled = true;
    let navigate = useNavigate();

    const imageRef = useRef();
    const params = useParams();
    const id = params.id;

    const load = async () => {
        try{
			const response = await api.get(`/curso/${id}`);
            const response_instrutor = await api.get('/user/instrutores');
            const instrutor_data = response_instrutor.data.data;
            const response_types = await api.get('/curso/categorias');
            const types = response_types.data.data;
            set_categorias(types.map((item) => ({
                label: item.name,
                value: item.id
            })));
            set_instrutores(instrutor_data.map((item) => ({
                label: `${item.nome} ${item.apelido}`,
                value: item.id
            })));
            const data = response.data.data;
            setImageUrl(data.imagem);

            form.setFieldsValue({
                title: data.title
            });
            form.setFieldsValue({
                descricao: data.descricao
            });
            form.setFieldsValue({
                visibilidade: data.visibilidade
            });
            form.setFieldsValue({
                instrutor: data.instrutor
            });
            form.setFieldsValue({
                category: data.category
            });
            setloading(false);
        }catch(error){
            console.log(error);
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    useEffect(() => {
        load();
    },[]);

    return (
        <div className='bg-white m-5 p-7'>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Curso</h3>
            <Form
                autoComplete="off"
                form={form}
                className="flix-form-vds"
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Título</Label>
                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <Input disabled={disabled}/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Descrição!',
                                        }
                                    ]}
                                >
                                    <TextArea disabled={disabled} rows={4} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Instrutor</Label>
                                <Form.Item
                                    name="instrutor"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input your instrutor!',
                                        }
                                    ]}
                                >
                                     <Select
                                            showSearch
                                            disabled={true}
                                            style={{ width: '100%' }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={instrutores}
                                        />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Categoria</Label>
                                <Form.Item
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your categoria!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        options={categorias}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Visibilidade</Label>
                                <Form.Item
                                    name="visibilidade"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your Visibilidade!',
                                        }
                                    ]}
                                >
                                    <Select
                                        disabled={disabled}
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Activo' },
                                            { value: 0, label: 'Inactivo' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full"> {/* conta corporativa*/}
                                <Label className='d-block'>Imagem do curso</Label>
                                <div onClick={() => imageRef.current.click()} className='video-background mt-2 mb-2'>
                                    <img src={imageUrl}></img>
                                    <input hidden type={'file'} onChange={handleChangeImage} ref={imageRef}></input>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
                <Link to={`/admin/cursos/edit/${id}`}><Button className='mt-4 bg-succuss-sflix' type="primary" success>Editar</Button></Link>
            </Form>
        </div>
    )
}

export default CursoView;

// expected output: "123.456,79 €"
export const FormatCurrency = (value, decimal = 2) => {
	return new Intl.NumberFormat("de-DE", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: decimal,
		maximumFractionDigits: decimal,
	})
		.format(isNaN(value) ? 0 : value)
		.replaceAll("€", "");
};

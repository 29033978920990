import { Button, Col, DatePicker, Form, Input, Row, Select, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Label } from 'reactstrap';

import image from '../../../assets/images/imagebg.png';
import api from '../../../services/api';

const { TextArea } = Input;

const FaculdadesCreate = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(image);
    const [loading, setloading] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const imageRef = useRef();

    const load = async () => {
        try {
            const response = await api.get('/payment/types');
            const types = response.data.data;
            setPaymentTypes(
                types.map((item) => ({
                    value: item.id,
                    label: item.name,
                })),
            );
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if (!beforeUpload(file[0])) {
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    const onFinish = async (values) => {
        console.log('finish', values);
        setloading(true);
        const Form = new FormData();
        Form.append('name', values.name);
        Form.append('descricao', values.descricao);
        Form.append('startDate', new Date(startDate));
        Form.append('endDate', new Date(endDate));
        if (imageFile) {
            Form.append('image', imageFile[0]);
            values.image = imageFile[0];
        }
        try {
            const response = await api.post('/settings/faculdades/create', Form);
            const data = response.data.data;
            console.log(data);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        } catch (error) {
            setloading(false);
            const error_response = error.response;
            if (typeof error_response !== 'undefined') {
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    };

    useEffect(() => {
        load();
    }, []);

    return (
        <div className="bg-white m-5 p-7" style={{ position: 'relative' }}>
            {loading ? (
                <div className="loader-flix-cont">
                    <div
                        className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
                    </div>
                </div>
            ) : null}
            {contextHolder}
            <h3 className="header-sflix text-dark mb-3">Criar</h3>
            <Form onFinish={onFinish} autoComplete="off" form={form}>
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className="flex-col">
                            <div className="w-full">
                                <Label className="">Nome</Label>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input nome!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className="">Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Descrição!',
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                            </div>

                            <div sm={12} className="w-full">
                                <Row className="flex-col">
                                    <div className="w-full">
                                        {' '}
                                        {/* conta corporativa*/}
                                        <Label className="d-block">Logotipo</Label>
                                        <div onClick={() => imageRef.current.click()} className="video-background mt-2 mb-2">
                                            <img src={imageUrl}></img>
                                            <input hidden type={'file'} onChange={handleChangeImage} ref={imageRef}></input>
                                        </div>
                                    </div>
                                </Row>

                                <div className="w-full">
                                    <Label className="">Data de inicio</Label>
                                    <Form.Item
                                        name="startDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Data de inicio',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            onChange={(date, dateString) => {
                                                setStartDate(dateString);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="w-full">
                                    <Label className="">Data de Fim</Label>
                                    <Form.Item
                                        name="endDate"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Data de Fim',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            onChange={(date, dateString) => {
                                                setEndDate(dateString);
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType="submit" className="mt-4 bg-succuss-sflix" type="primary" success>
                    Guardar
                </Button>
            </Form>
        </div>
    );
};

export default FaculdadesCreate;

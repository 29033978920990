
export const isUserLoggedIn = () => getCookie("user");

export function getCookie(name) {
    var docCookie = document.cookie;
    var prefix = name + "=";
    var begin = docCookie.indexOf("; " + prefix);

    if (begin == -1) {
        begin = docCookie.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = docCookie.length;
        }
    }
    return decodeURI(docCookie.substring(begin + prefix.length, end));
}

export function delete_cookie( name ) {
    document.cookie = name+'=; Max-Age=-99999999;'; 
  }
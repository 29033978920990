import React, { useEffect, useState } from 'react'
import { 
    Button, 
    Space, 
    Table, 
    Tag,
    Row,
    Col,
    Input,
	message
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    FolderViewOutlined
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useParams } from 'react-router';
import api from '../../../services/api';
import Swal from 'sweetalert2'

const Videos = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState();
	const [search,setSearch] = useState('');
	const [index,setIndex] = useState(1);
	const [loading,setLoading] = useState(false);

	const params = useParams();
    const id = params.id;

	const load = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/cursos/modules/${id}`, {
				params: { 
					search,
					index
				}
			});
            const modules = response.data.data;
			setData(modules);
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
        }
	}

	const handleChange = (e) => {
		const value = e.target.value;
		setSearch(value);
    };

	useEffect(() => {
		load();
	},[search,index]);


	const showPopconfirmDelete = (id) => {
		Swal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/curso/module/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const columns = [
        {
            title: '',
            key: 'capa',
            render: (row) => {
              return(
					<div className='modulo-capa'>
						<img src={row.video_frame}></img>
					</div>
              )
            }
        },
        {
			title: 'Título',
			dataIndex: 'title',
			key: 'title'
        },
        {
			title: 'Descrição',
			dataIndex: 'descricao',
			key: 'descricao',
			render: (row) => {
				return(
					`${row?.substr(0,45)}...`
				)
			}
        },
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'visibilidade',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        },
        {
			title: 'Action',
			key: 'action',
			render: (row) => (
				<Space size="middle">
					<Link to={`/admin/cursos/video/quiz/${row.id}`}>
						<Button shape="">
							Quiz
						</Button>
					</Link>
					<Link to={`/admin/cursos/modules/videos/${id}/edit/${row.id}`}>
						<Button shape="circle">
							<EditOutlined />
						</Button>
					</Link>
					<Link to={`/admin/cursos/modules/videos/${id}/view/${row.id}`}>
						<Button shape="circle">
							<FolderViewOutlined/>
						</Button>
					</Link>
					<Button onClick={() => showPopconfirmDelete(row.id)} danger shape="circle">
						<DeleteOutlined />
					</Button>
				</Space>
			)
        },
    ];

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
			{contextHolder}
			{ loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
			<div className='flex-ttp-items mb-4'>
				<h3 className='header-sflix text-dark mb-3'>Modulos</h3>
                <Link to={`/admin/cursos/modules/videos/${id}/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <div className='mt-3 mb-4'>
                <Row gutter={8}>
                    <Col span={8}>
                        <Input onChange={handleChange} placeholder="Pesquisar" />
                    </Col>
                </Row>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
            />
        </div>
    )
}

export default Videos;
import React, { useEffect, useState } from 'react'
import {
    Label
} from "reactstrap";

import { 
    Button,
    Input,
    Row,
    Select,
    Form,
    message
} from 'antd';

import api from '../../../services/api';

const BookCategoriesCreate = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);

    const load = async () => {
		try{
			const response = await api.get('/library/categorias');
			const cats = response.data.data;
            const data_categorias = [];
            cats.map((item) => {
                if(!item.parent_id){
                    data_categorias.push({
                        value: item.id,
                        label: item.name
                    });
                }
            })
			setData(data_categorias);
        }catch(error){
            console.log(error);
        }
	}

    useEffect(() => {
		load();
	},[]);

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinish = async (values) => {
        setLoading(true);
        try{
            const response = await api.post(`/library/category/create`,values);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setLoading(false);
            load();
        }catch(error){
            setLoading(false);
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            {contextHolder}
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            <h3 className='header-sflix text-dark mb-3'>Categorias</h3>
            <Form
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Nome</Label>
                                <Form.Item
                                    name="nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your nome!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Categoria</Label>
                                <Form.Item
                                    name="categoria"
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[{
                                            value: null,
                                            label: ""
                                        },...data]}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Status</Label>
                                <Form.Item
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your status!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default BookCategoriesCreate;

import React, { useEffect, useState } from 'react'
import { Button, Space, Table, message} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
	PlusOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../../services/api';

const Pacotes = () => {
	const MySwal = withReactContent(Swal);
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState([]);

	const load = async () => {
		try{
			const response_pacotes = await api.get('/pacotes');
			const pacotes = response_pacotes.data.data;
			setData(pacotes);
        }catch(error){
            console.log(error);
        }
	}

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/pacote/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	const showPopconfirmDelete = (id) => {
		MySwal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

    const columns = [
        {
          title: 'Nome',
          dataIndex: 'name',
          key: 'name',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Preço',
          dataIndex: 'preco',
          key: 'preco',
        },
        {
          title: 'Duração(dias)',
          dataIndex: 'duracao',
          key: 'duracao',
        },
        {
          title: 'Descrição',
          dataIndex: 'descricao',
          key: 'descricao',
        },
        {
          title: 'Action',
          key: 'action',
          render: (row) => (
            <Space size="middle">
				<Link to={`/admin/settings/pacotes/specs/${row.id}`}>
					<Button shape="circle">
						<PlusOutlined />
					</Button>
				</Link>
              	<Link to={`/admin/settings/pacotes/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
				<Button onClick={() => showPopconfirmDelete(row.id)} danger shape="circle">
					<DeleteOutlined />
				</Button>
            </Space>
          ),
        },
    ];

	useEffect(() => {
		load();
	}, []);

    return (
        <div className='bg-white m-5 p-7'>
			{contextHolder}
            <div className='flex-ttp-items mb-4'>
                <h3 className='header-sflix text-dark'>Pacotes</h3>
                <Link to={`/admin/settings/pacotes/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
            />
        </div>
    )
}

export default Pacotes
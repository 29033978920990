import React, { useEffect, useState } from 'react'
import {
    Label,
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    message
} from 'antd';

import api from '../../services/api';
const { TextArea } = Input;

const TutoresCreate = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [loading,setloading] = useState();
    const [userTypes,setUserTypes] = useState([]);

    const load = async () => {
        try{
            const response = await api.get('/user/types');
            const users = response.data.data;

            setUserTypes(users.map((item) => ({
                value: item.id,
                label: item.name
            })));

        }catch(error){
            console.log(error);
        }
    }

    const onFinish = async (values) => {
        setloading(true);
        const Form = new FormData();
        Form.append('nome',values.nome);
        Form.append('apelido',values.apelido);
        Form.append('email',values.email);
        Form.append('contacto',values.contacto);
        Form.append('biografia',values.biografia);

        Form.append('website',values.website || '');
        Form.append('twitter',values.twitter || '');
        Form.append('linkedIn',values.linkedIn || '');
        Form.append('youtube',values.youtube || '');

        try{
            const response = await api.post('/tutores/create', Form);
            const data = response.data.data;
            console.log(response);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    useEffect(() => {
        load()
    },[]);

    return (
        
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    class="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Criar</h3>
            <Form
            onFinish={onFinish}
            //onFinishFailed={onFinishFailed}
            autoComplete="off"
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Nome</Label>
                                <Form.Item
                                    name="nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your nome!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Apelido</Label>
                                <Form.Item
                                    name="apelido"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your apelido!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Email</Label>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Contacto</Label>
                                <Form.Item
                                    name="contacto"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your contacto!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Biografia</Label>
                                <Form.Item
                                    name="biografia"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your biografia!',
                                        }
                                    ]}
                                >
                                    <TextArea/>
                                </Form.Item>
                            </div>
                        
                        </Row>
                    </div>
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full"> {/* conta corporativa*/}
                                <Label className=''>Website</Label>
                                <Form.Item
                                    name="website"
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Twitter</Label>
                                <Form.Item
                                    name="twitter"
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Facebook</Label>
                                <Form.Item
                                    name="facebook"
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>LinkedIn</Label>
                                <Form.Item
                                    name="linkedIn"
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Youtube</Label>
                                <Form.Item
                                    name="youtube"
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default TutoresCreate;

import React, { useEffect, useState } from 'react'
import { 
    Button, 
    Space, 
    Table, 
    Tag,
    Select,
    Row,
    Col,
    Input
} from 'antd';
import {
    FolderViewOutlined
} from '@ant-design/icons';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import api from '../../../services/api';
import { FormatCurrency } from '../../../utils/FormatCurrency';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const Subscricoes = () => {
	const [data,setData] = useState([]);
	const [search,setSearch] = useState('');
	const [index,setIndex] = useState(1);
	const [active_subscriptions,set_active_subscriptions] = useState('');
	const [innactive_subscriptions,set_innactive_subscriptions] = useState('');
	const [loading,setLoading] = useState(false);
	const [user_types,set_user_types] = useState([]);

	const [data_graph,set_data_graph] = useState({
        labels: [],
        datasets: [
          {
            label: 'Subscrições',
            data: [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Usuários',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          }
        ]
    });
	const [data_pie,set_data_pie] = useState({
        labels: [],
        datasets: [
            {
				label: 'Tipo de Subscrições',
				data: [],
				backgroundColor: [
					'rgba(255, 99, 132, 0.2)',
					'rgba(54, 162, 235, 0.2)',
					'rgba(255, 206, 86, 0.2)',
					'rgba(75, 192, 192, 0.2)',
					'rgba(153, 102, 255, 0.2)',
					'rgba(255, 159, 64, 0.2)',
				],
				borderColor: [
					'rgba(255, 99, 132, 1)',
					'rgba(54, 162, 235, 1)',
					'rgba(255, 206, 86, 1)',
					'rgba(75, 192, 192, 1)',
					'rgba(153, 102, 255, 1)',
					'rgba(255, 159, 64, 1)',
				],
				borderWidth: 1,
            }
        ]
    });

	const load = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/pagamentos/subscricoes`, {
				params: { 
					search,
					index,
				}
			});
			const relatorio_response = await api.get('/relatorios/subscricoes');
            const logs = response.data.data;
			const relatorio = relatorio_response.data.data;

			let graph_data_lines = {...data_graph};
			graph_data_lines.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Ago','Sept','Out','Nov','Dez']
			graph_data_lines.datasets[0].data = relatorio.subscricoes.data_by_month;
			graph_data_lines.datasets[1].data = relatorio.usuarios.users;
			set_data_graph({...graph_data_lines});

			const pie_data = {...data_pie};
			pie_data.labels = relatorio.pacotes.pacote_labels;
			pie_data.datasets[0].data = relatorio.pacotes.pacote_data;

			set_data_pie(pie_data);
			set_active_subscriptions(relatorio.subcriecoes_activas);
			set_innactive_subscriptions(relatorio.subcriecoes_inactivas);
			setData(logs);
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
        }
	}

	const filter = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/pagamentos/subscricoes`, {
				params: { 
					search,
					index,
				}
			});
            const logs = response.data.data;
			setData(logs);
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
        }
	}

	useEffect(() => {
		load();
	},[]);

	useEffect(() => {
		filter();
	},[search,index]);

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Subscrições mensais',
          },
        },
    };

    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Usuario',
          key: 'usuario',
		  render: (row) => <span>{row.nome} {row.apelido}</span>
        },
        {
            title: 'Tipo pagamento',
            dataIndex: 'payment_type',
            key: 'payment_type',
        },
		{
			title: 'Pacote',
			dataIndex: 'pacote',
			key: 'pacote',
		  },
        {
            title: 'Duração(dias)',
            dataIndex: 'duracao',
            key: 'duracao',
        },
		{
			title: 'Preço',
			key: 'preco',
			render: (row) => <span>{FormatCurrency(row.preco)}</span>
		},
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        }
    ];

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    
    return (
        <div>
            <div className='m-5'>
                <div className='plaform-cards'>
                    <div className='card-flix-tp'>
                        <div className='card-inner-cont-fl'>
                            <div className='card-f-left'>
                                <div className='text-ttl-card'>Subscrições Inactiva</div>
                            </div>
                            <div className='card-f-right'>
                                <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{active_subscriptions}</h3>
                            </div>
                        </div>
                        <div className='mt-5 mb-3' style={{height: 5, backgroundColor: '#0fa966'}}></div>
                    </div>
                    <div className='card-flix-tp'>
                        <div className='card-inner-cont-fl'>
                            <div className='card-f-left'>
                                <div className='text-ttl-card'>Subscrições Activas</div>
                            </div>
                            <div className='card-f-right'>
                                <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{innactive_subscriptions}</h3>
                            </div>
                        </div>
                        <div className='mt-5 mb-3' style={{height: 5, backgroundColor: '#0fa966'}}></div>
                    </div>
                </div>
            </div>

            <div className='m-5 flex-graphs-container'>
                <div className='graph-card-item'>
                    { data_graph.labels[0] ? <Line options={options} data={data_graph} /> : null }
                </div>
                <div className='graph-card-item'>
                    { data_pie.labels[0] ? <Pie id='ddd-dd' data={data_pie}  options={{ maintainAspectRatio: false }} /> : null }
                </div>
            </div>

            <div className='bg-white m-5 p-7'>
                <h3 className='header-sflix text-dark mb-3'>Subscrições</h3>
                <div className='mt-3 mb-4'>
                    <Row gutter={8}>
                        <Col span={6} className="">
                            <Input onChange={(e) => setSearch(e.target.value)} placeholder="Pesquisar" />
                        </Col>
                        {
							/*
							<Col span={6} className="">
                            <Select
                                placeholder="Pacote"
                                style={{width: '100%'}}
                                className=""
                                onChange={handleChange}
                                options={[
                                    { value: '12', label: 'Recentes' },
                                    { value: '10', label: 'Antigos' },
                                    { value: '17', label: 'Destaque' }
                                ]}
                            />
                        </Col>
                        <Col span={6} className="">
                            <Select
                                placeholder="Tipo de conta"
                                style={{width: '100%'}}
                                className=""
                                onChange={handleChange}
                                options={[
                                    { value: '12', label: 'Recentes' },
                                    { value: '10', label: 'Antigos' },
                                    { value: '17', label: 'Destaque' }
                                ]}
                            />
                        </Col>
                        <Col span={6} className="">
                            <Select
                                placeholder="Estado"
                                style={{width: '100%'}}
                                className=""
                                onChange={handleChange}
                                options={[
                                    { value: '12', label: 'Recentes' },
                                    { value: '10', label: 'Antigos' },
                                    { value: '17', label: 'Destaque' }
                                ]}
                            />
                        </Col>
							*/
						}
                    </Row>
                </div>
                <Table 
                    columns={columns} 
                    dataSource={data} 
                />
            </div>
        </div>
    )
}

export default Subscricoes;
import api from '../../api';

export async function apiCreateBulkUsers(props: { users: { nome: string; apelido: string; email: string; contacto: string }[]; userEmails: string[]; university: string }) {
    try {
        const { university, userEmails, users } = props;
        const response = await api.post(`/user/create/bulk`, {
            users,
            userEmails,
            university,
        });
        return {
            status: true,
            data: response.data,
            message: 'Sucesso',
        };
    } catch (error: any) {
        const defaultErrorMessage = 'Não foi possivel possível processar o teu pedido!';
        // console.log({ error: error.response, message: JSON.parse(error.response?.data?.message) });
        if (!error.response) {
            return {
                status: false,
                data: {},
                message: defaultErrorMessage,
            };
        } else {
            let message = 'Não foi possivel possível processar o teu pedido! Contacte o suporte';
            if (error.response?.data?.message) {
                if (error.response?.data?.message.includes('validation')) {
                    message = JSON.parse(error.response?.data?.message)[0].message;
                } else {
                    message = error.response?.data?.message;
                }
            }
            return {
                status: false,
                data: {},
                message: message,
            };
        }
    }
}

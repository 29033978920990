import axios from "axios";

const url = process.env.REACT_APP_SERVER || "http://localhost:3333";

const instance = axios.create({
    baseURL: url,
    withCredentials: true,
});

export default instance;

import React, { useEffect, useRef, useState } from 'react'
import { Card, Button, Space, Table, Tag} from 'antd';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';
import api from '../../services/api';
import { Link } from 'react-router-dom';

import {
    EditOutlined
} from '@ant-design/icons';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const HomePage = () => {
    const modalRef = useRef();
	const [data,setData] = useState([]);
	const [search,setSearch] = useState('');
	const [tipo,setTipo] = useState('');
	const [total_users,set_total_users] = useState(0);
	const [total_subscricoes, set_total_subscricoes] = useState(0);
	const [total_cursos,set_total_cursos]= useState(0);
	const [total_views,set_total_views] = useState(0);

	const [data_graph,set_data_graph] = useState({
        labels: [],
        datasets: [
          {
            label: 'Subscrições',
            data: [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            label: 'Usuários',
            data: [],
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          }
        ]
    });

	const load = async () => {
		try{
			const users_new = await api.get('/users/new',{
				params: { 
					search,
					tipo
				}
			});
			  api.get('/relatorios/subscricoes').then((data)=>{
                const relatorio = data.data;
                let graph_data_lines = {...data_graph};
                graph_data_lines.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Ago','Sept','Out','Nov','Dez']
                graph_data_lines.datasets[0].data = relatorio.subscricoes.data_by_month;
                graph_data_lines.datasets[1].data = relatorio.usuarios.users;
                set_data_graph({...graph_data_lines});
                set_total_users(relatorio.users_registrados);
                set_total_subscricoes(relatorio.user_packages);
                set_total_cursos(relatorio.courses);
                set_total_views(relatorio.views);
            });
			
			setData(users_new.data.data);

	

        }catch(error){
            console.log(error);
        }
	}

	useEffect(() => {
		load();
	},[]);

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Relatorio',
          },
        },
    };

	const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
			render: (text) => <Link to={''}>{text}</Link>,
		},
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'nome',
        },
		{
			title: 'Apelido',
			dataIndex: 'apelido',
			key: 'apelido',
		},
        {
			title: 'email',
			dataIndex: 'email',
			key: 'email',
		},
        {
          title: 'contacto',
          dataIndex: 'contacto',
          key: 'contacto',
        },
		{
			title: 'tipo',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Data',
			key: 'data',
		},
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        },
        {
          title: 'Ação',
          key: 'action',
          render: (row) => (
            <Space size="middle">
                <Link to={`/admin/users/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
            </Space>
          ),
        },
    ];

    const listenScrollEvent = (event) => {
        if (window.scrollY > 73) {
            var navbar = document.querySelector('.navbar-container');
            navbar.style.backgroundColor = '#000';
        } else if (window.scrollY < 70) {
            var navbar = document.querySelector('.navbar-container');
            navbar.style.backgroundColor = 'rgb(0, 0, 0, 0.0)';
        }
    }

    const openCourseInfo = (id) => {
        modalRef.current.open(id);
    }

  return (
    <div className='main-cont-inner'>
        <div className='plaform-cards'>
            <div className='card-flix-tp'>
                <div className='card-inner-cont-fl'>
                    <div className='card-f-left'>
                        <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{total_users}</h3>
                        <div className='text-ttl-card'>Usuários Registrados</div>
                    </div>
                    <div className='card-f-right'>
                        <div className='icon-ilustration' style={{backgroundColor: 'rgba(15, 169, 102, 0.259'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill='#0fa966' d="M66.565 905.087q-14.674 0-24.37-9.696-9.695-9.695-9.695-24.369V804.63q0-36.673 18.837-66.489 18.837-29.815 52.511-44.771 73.478-32 132.695-46.24 59.218-14.239 121.935-14.239 63.196 0 121.794 14.12 58.598 14.119 132.076 46.359 33.674 14.717 53.011 44.532 19.337 29.815 19.337 66.728v66.392q0 14.674-9.696 24.369-9.696 9.696-24.37 9.696H66.565Zm656.848 0q10.478-2.717 17.196-12.293 6.717-9.576 6.717-23.772v-63.913q0-63.479-31.88-104.935-31.881-41.457-84.359-69.087 67.087 8.239 126.533 23.5 59.445 15.261 96.967 34.783 33.717 19 53.315 48.913 19.598 29.913 19.598 67.543v65.196q0 14.674-9.696 24.369-9.695 9.696-24.369 9.696H723.413ZM358.478 571.891q-67.435 0-110.152-42.717-42.717-42.718-42.717-110.392 0-67.434 42.717-110.152 42.717-42.717 110.152-42.717T468.75 308.63q42.837 42.718 42.837 110.152 0 67.674-42.837 110.392-42.837 42.717-110.272 42.717Zm368.848-153.109q0 67.435-42.837 110.272t-110.271 42.837q-11.24 0-24.5-1.619-13.261-1.62-24.979-5.859 24.479-25.478 36.979-62.576t12.5-83.055q0-45.717-12.5-80.934-12.5-35.218-36.979-64.457 11.239-3.239 24.62-5.359 13.38-2.119 24.859-2.119 67.434 0 110.271 42.837t42.837 110.032Z"/></svg>
                        </div>
                    </div>
                </div>
                <div className='mt-5' style={{height: 5, backgroundColor: '#0fa966'}}></div>
            </div>
            <div className='card-flix-tp'>
                <div className='card-inner-cont-fl'>
                    <div className='card-f-left'>
                        <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{total_subscricoes}</h3>
                        <div className='text-ttl-card'>Subscrições</div>
                    </div>
                    <div className='card-f-right'>
                        <div className='icon-ilustration' style={{backgroundColor: 'rgba(15, 169, 102, 0.259'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill='#0fa966' d="M576.761 958.109 451.63 832.739q-10.195-10.195-10.195-23.511 0-13.315 11.195-24.271 8.957-9.196 23.272-9.196 14.316 0 24.511 9.196L600.63 886.413l214.696-214.456q9.957-10.196 23.272-9.696 13.315.5 23.511 10.696 9.956 9.956 9.956 23.771 0 13.816-10.717 24.772L624.5 958.109q-10.196 10.435-23.87 10.435-13.673 0-23.869-10.435Zm-434.609-56.131q-28.348 0-48.239-19.891t-19.891-48.239V318.152q0-28.348 19.891-48.359 19.891-20.011 48.239-20.011h675.696q28.348 0 48.359 20.011 20.011 20.011 20.011 48.359v236.37h-38.457L600.63 804.413l-61.652-62.891q-27.195-27.196-65.13-27.576-37.935-.381-64.652 26.576Q382 767.717 381.5 806.033q-.5 38.315 26.696 65.511l29.434 30.434H142.152Zm0-347.456h675.696v-139H142.152v139Z"/></svg>
                        </div>
                    </div>
                </div>
                <div className='mt-5' style={{height: 5, backgroundColor: '#0fa966'}}></div>
            </div>
            <div className='card-flix-tp'>
                <div className='card-inner-cont-fl'>
                    <div className='card-f-left'>
                        <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{total_cursos}</h3>
                        <div className='text-ttl-card'>Cursos</div>
                    </div>
                    <div className='card-f-right'>
                        <div className='icon-ilustration' style={{backgroundColor: 'rgba(15, 169, 102, 0.259'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill='#0fa966' d="m476.696 647.5 166.869-107.217q15.913-10.264 15.913-28.382 0-18.119-15.913-28.553L476.696 376.37q-17.152-11.196-34.685-1.628-17.533 9.569-17.533 30.215v213.956q0 20.63 17.533 30.206 17.533 9.577 34.685-1.619ZM266.218 857.913q-27.599 0-47.865-20.266-20.266-20.266-20.266-47.865V234.087q0-27.698 20.266-48.034t47.865-20.336h555.695q27.698 0 48.034 20.336t20.336 48.034v555.695q0 27.599-20.336 47.865-20.336 20.266-48.034 20.266H266.218Zm-128.131 128.37q-27.698 0-48.034-20.336t-20.336-48.034V328.152q0-14.674 9.696-24.369 9.696-9.696 24.489-9.696 14.794 0 24.489 9.696 9.696 9.695 9.696 24.369v589.761h589.761q14.674 0 24.369 9.696 9.696 9.695 9.696 24.489 0 14.793-9.696 24.489-9.695 9.696-24.369 9.696H138.087Z"/></svg>
                        </div>
                    </div>
                </div>
                <div className='mt-5' style={{height: 5, backgroundColor: '#0fa966'}}></div>
            </div>
            <div className='card-flix-tp'>
                <div className='card-inner-cont-fl'>
                    <div className='card-f-left'>
                        <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{total_views}</h3>
                        <div className='text-ttl-card'>Visualizações</div>
                    </div>
                    <div className='card-f-right'>
                        <div className='icon-ilustration' style={{backgroundColor: 'rgba(15, 169, 102, 0.259'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill='#0fa966' d="M182.63 941.5q-28.103 0-48.117-20.013Q114.5 901.473 114.5 873.37V278.63q0-28.103 20.013-48.117Q154.527 210.5 182.63 210.5h594.74q28.103 0 48.117 20.013Q845.5 250.527 845.5 278.63v594.74q0 28.103-20.013 48.117Q805.473 941.5 777.37 941.5H182.63ZM180 876h600V356H180v520Zm300.041-105Q400 771 337 727.849q-63-43.152-92-112Q274 547 336.959 504t143-43Q560 461 623 504.151q63 43.152 92 112Q686 685 623.041 728t-143 43Zm-.159-105Q459 666 444.5 651.382q-14.5-14.617-14.5-35.5Q430 595 444.618 580.5q14.617-14.5 35.5-14.5Q501 566 515.5 580.618q14.5 14.617 14.5 35.5Q530 637 515.382 651.5q-14.617 14.5-35.5 14.5Zm.118 30q33.6 0 56.8-23.2Q560 649.6 560 616q0-33.6-23.2-56.8Q513.6 536 480 536q-33.6 0-56.8 23.2Q400 582.4 400 616q0 33.6 23.2 56.8Q446.4 696 480 696Z"/></svg>
                        </div>
                    </div>
                </div>
                <div className='mt-5' style={{height: 5, backgroundColor: '#0fa966'}}></div>
            </div>
        </div>
        <div className='graph-cont-items mt-5'>
            <div className='graph-card-item'>
                { data_graph.datasets[0].data.length ? <Line options={options} data={data_graph} /> : null }
            </div>
            <div className='graph-card-item'>
               
            </div>
        </div>
        <div className='mt-5 bg-white p-3'>
            <h3 className='header-sflix text-dark mb-3'>Novos usuários</h3>
            <Table columns={columns} dataSource={data} />
        </div>
    </div>
  )
}

export default HomePage;
import React, { useEffect, useRef, useState } from 'react'
import {
    Label
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    message
} from 'antd';

import image from '../../../assets/images/imagebg.png';
import api from '../../../services/api';

const PaymentMethodsCreate = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [paymentTypes,setPaymentTypes] = useState([]);
    const [imageFile,setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(image);
    const [loading,setloading] = useState();

    const imageRef = useRef();

    const load = async () => {
        try{
            const response = await api.get('/payment/types');
            const types = response.data.data;
            setPaymentTypes(types.map((item) => ({
                value: item.id,
                label: item.name
            })));
        }catch(error){
            console.log(error);
        }
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    const onFinish = async (values) => {
        console.log('finish',values)
        setloading(true);
        const Form = new FormData();
        Form.append('nome',values.nome);
        Form.append('tipo',values.tipo);
        Form.append('tipo_integracao',values.tipo_integracao);
        Form.append('conta',values.conta);
        Form.append('status',values.status);

        if(imageFile){
            Form.append('image',imageFile[0]);
            values.image = imageFile[0];
        }

        try{
            const response = await api.post('/payment/methods/create', Form);
            const data = response.data.data;
            console.log(data);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    useEffect(() => {
        load();
    },[]);

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Criar Metodo de Pagamento</h3>
            <div className='profile-image-container mb-5'>
                <div onClick={() => {
                    imageRef.current.click();
                }} className='user-profile-image-container'>
                    <img src={imageUrl}></img>
                </div>
                <input hidden type={'file'} onChange={handleChangeImage} ref={imageRef}></input>
            </div>
            <Form
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Nome</Label>
                                <Form.Item
                                    name="nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your nome!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Tipo</Label>
                                <Form.Item
                                    name="tipo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your Tipo!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={paymentTypes}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Tipo de Integrações</Label>
                                <Form.Item
                                    name="tipo_integracao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your Tipo de Integrações!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 'cms', label: 'CMS' },
                                            { value: 'coding', label: 'Coding' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Conta de recebimento do valor</Label>
                                <Form.Item
                                    name="conta"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your conta!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Status</Label>
                                <Form.Item
                                    name="status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your status!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Active' },
                                            { value: 0, label: 'Inactive' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default PaymentMethodsCreate;

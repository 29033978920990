import React, { Profiler, useEffect, useRef, useState } from 'react'
import { Input, Avatar, Button, Badge, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

import { useCookies } from "react-cookie";
import api from '../services/api';
import { getCookie } from '../utils/utils';

export const Navbar = () => {
    const [profile, setProfile] = useState('');
    const [username, setUsername] = useState('');
    const [id, setId] = useState('');
    const [type, setType] = useState('');
    const dropdown_ref = useRef();
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);

    const [notifications, setNotifications] = useState([
        {
            label: <a href="https://www.antgroup.com">1st menu item</a>,
            key: '0',
        },
    ]);

    const load = async () => {
        try {
            //const notify = await api.get('/admin/notifications');
            const token = getCookie('_lib_admin_token');
            const formatedToken = token.includes(';') ? token.split(';')[0] : token
            if (token) api.defaults.headers.Authorization = "Bearer " + formatedToken;

            const response = await api.get('/me');
            const user = response.data;

            setId(user.id);
            setUsername(`${user.nome} ${user.apelido}`);
            setType(user.type);
            setProfile(user.profile_image)
            setNotifications([]);
        } catch (error) {
            console.log(error);
        }
    }

    const kill_session = async () => {
        try {
            await api.post('/auth/logout');
            removeCookie('user', { path: '/' });
            window.location.href = '/admin/login'
            return;
        } catch (error) {
            removeCookie('user', { path: '/' });
            console.log(error);
        }
    }

    const items_profile = [
        {
            label: (
                <Link to={`/admin/users/edit/${id}`}>Profile</Link>
            ),
            key: '0',
        },
        {
            label: <Link to={`/admin/settings/general`}>Configurações</Link>,
            key: '1',
        },
        {
            type: 'divider',
        },
        {
            label: <button onClick={kill_session}>Logout</button>,
            key: '2',
        }
    ];

    useEffect(() => {
        load();
    }, []);

    return (
        <div className='nav-inner'>
            <nav className=''>
                <div className='ttl-dash'>
                    <button className='more-nav-items'>
                        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M399 856q-19.75 0-33.375-13.877t-13.625-34Q352 788 365.625 774.5 379.25 761 399 761h411q19.75 0 33.375 13.675Q857 788.351 857 808.175 857 828 843.375 842 829.75 856 810 856H399ZM150 390q-19.75 0-33.375-13.675Q103 362.649 103 342.825 103 323 116.625 309 130.25 295 150 295h660q19.75 0 33.375 14.175 13.625 14.176 13.625 34Q857 363 843.375 376.5 829.75 390 810 390H150Zm249 233q-19.75 0-33.375-13.675Q352 595.649 352 575.825 352 556 365.625 542.5 379.25 529 399 529h411q19.75 0 33.375 13.675Q857 556.351 857 576.175 857 596 843.375 609.5 829.75 623 810 623H399Z" /></svg>
                    </button>
                    <Link>
                        Dashboard
                    </Link>
                </div>
                <div className='options-nav'>
                    <Dropdown menu={{ items: notifications }} trigger={['click']}>
                        <Badge count={notifications.length}>
                            <button>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path d="M184.127 861.261q-15.018 0-24.714-9.696-9.696-9.695-9.696-24.489 0-14.793 9.696-24.489 9.696-9.696 24.609-9.696h51.847V493.587q0-84.412 50.457-153.054 50.456-68.642 133.609-86.459V226.37q0-24.894 17.636-41.099 17.635-16.206 42.429-16.206t42.429 16.206q17.636 16.205 17.636 41.099v27.704q83.392 17.817 134.348 86.459 50.957 68.642 50.957 153.054v299.304h50.848q14.644 0 24.474 9.696t9.83 24.489q0 14.794-9.83 24.489-9.83 9.696-24.474 9.696H184.127ZM480.12 981.978q-32.837 0-57.435-24.006-24.598-24.007-24.598-57.907h164.065q0 33.957-24.098 57.935-24.097 23.978-57.934 23.978Z" /></svg>
                            </button>
                        </Badge>
                    </Dropdown>
                    <div className='profile-desc ml-2'>
                        <div className='info-sect'>
                            <div className='text-xs bold'>{username}</div>
                            <div className='' style={{ fontSize: 10 }}>{type}</div>
                        </div>
                        <Dropdown menu={{ items: items_profile }} arrow>
                            {
                                profile
                                    ?
                                    <Avatar src={<img src={profile} alt="avatar" />} />
                                    :
                                    <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>U</Avatar>
                            }
                        </Dropdown>
                    </div>
                </div>
            </nav>
        </div>
    )
}

import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import LayoutMain from '../layouts/LayoutMain'
import { ErrorPage } from '../views/ErrorPage'
import Login from '../views/login'
import RouteList from './Routes';
import { useCookies } from "react-cookie";

import api from '../services/api';
import { getCookie, isUserLoggedIn } from '../utils/utils'

const Router = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);

    const verify_login = async () => {
        try {

            const token = getCookie('_lib_admin_token');
            const user = getCookie('user');

            if (token && user) {
                const formatedToken = token.includes(';') ? token.split(';')[0] : token
                api.defaults.headers.Authorization = "Bearer " + formatedToken;
                setCookie("user", user, {
                    path: "/",
                    maxAge: 28800,
                });

            } else {
                const response = await api.post("/auth/refresh-token");
                const user = response.data.data;

                if (user.user_type != 1) {
                    await api.post('/auth/logout');
                    removeCookie('user', { path: '/' });
                    window.location.href = '/admin/login'
                }

                setCookie("user", user.userId, {
                    path: "/",
                    maxAge: 28800,
                });
            }



        } catch (error) {
            console.log('user not authenticated');
            removeCookie('user', { path: '/' });
            const error_response = error.response;
            if (typeof error_response !== 'undefined') {
                console.log(error_response.data.message)
            }
        }

        console.log('flux loading ended');
    }

    const FinaLComponet = (props) => {
        const route = props.route;

        if (!isUserLoggedIn() && route.authRoute) {
            console.log('user_logged');
            return <route.render {...props} />;
        } else if (isUserLoggedIn() && route.authRoute) {
            return <Navigate to={'/admin/dashboard'} />
        } else if (!isUserLoggedIn() && route.authRoute == undefined) {
            console.log('not logged');
            return <Navigate to={'/admin/login'} />
        }

        return <route.render {...props} />;
    }

    const RouterResolver = () => {
        return RouteList.map((route) => {
            return (
                route.unique ?
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact === true}
                        element={
                            <FinaLComponet
                                route={route}
                            />
                        }
                    />
                    :
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact === true}
                        element={
                            <LayoutMain>
                                <FinaLComponet
                                    route={route}
                                />
                            </LayoutMain>
                        }
                    />
            )
        })
    }

    useEffect(() => {
        verify_login();
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                {RouterResolver()}
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;

import React, { useEffect, useState } from 'react'
import { useCookies } from "react-cookie";
import api from '../../services/api';

const Logout = () => {
    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [loading,setloading] = useState(false);
    const kill_session = async () => {
        try{
            const response = await api.post('/auth/logout');
            removeCookie('user');
        }catch(error){
            removeCookie('user');
            console.log(error);
        }
    }

    kill_session();

    useEffect(() => {
        removeCookie('user');
    },[]);

    return (
        <div className='login-wrapper '>
            Logout...
        </div>
    )
}

export default Logout
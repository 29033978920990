import React, { useEffect, useState } from 'react'
import { 
    Table, 
    Tag,
    Row,
    Col,
    Input,
	message
} from 'antd';

import api from '../../../services/api';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';

const LogsLogin = () => {
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState();
	const [search,setSearch] = useState('');
	const [index,setIndex] = useState(1);
	const [loading,setLoading] = useState(false);

	const load = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/logs`, {
				params: { 
					search,
					index
				}
			});
            const logs = response.data.data;
			setData(logs);
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
        }
	}

	useEffect(() => {
		load();
	},[search]);

    const columns = [
        {
          title: 'id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Usuário',
          key: 'user',
		  render: (row) => <Link to={``}>{row.nome} {row.apelido}</Link>,
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
        },
        {
            title: 'Cidade',
            dataIndex: 'cidade',
            key: 'cidade',
        },
        {
            title: 'Pais',
            dataIndex: 'pais',
            key: 'pais',
        },
        {
          title: 'Endereço',
          dataIndex: 'endereco',
          key: 'endereco',
        },
        {
            title: 'Navegador',
            dataIndex: 'navegador',
            key: 'navegador',
        },
		{
            title: 'Data',
            dataIndex: 'created_at',
            key: 'Data',
        },
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        }
    ];
    
    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
			{contextHolder}
			{ loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            <h3 className='header-sflix text-dark mb-3'>Login Logs</h3>
            <div className='mt-3 mb-4'>
                <Row gutter={8}>
                    <Col span={8}>
                        <Input onChange={(e) => setSearch(e.target.value)} placeholder="Pesquisar" />
                    </Col>
                </Row>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
            />
        </div>
    )
}

export default LogsLogin;
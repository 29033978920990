import React, { useEffect, useRef, useState } from 'react'
import { 
    Button, 
    Space, 
    Table, 
    Tag,
    Row,
    Col,
    Input,
    Select,
	message
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import api from '../../services/api';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Tutores = () => {
	const MySwal = withReactContent(Swal);
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState([]);
	const [search,setSearch] = useState('');
	const [tipo,setTipo] = useState('');
	const [pagination, set_pagination] = useState({
        current: 1,
        pageSize: 20
    })
	const [loading,set_loading] = useState(false);

	const load = async (data = {...pagination}) => {
		set_loading(true);
		try{
            const response = await api.get('/tutores', {
				params: {
					search,
					tipo,
					page: data.current,
				}
			});
            const users = response.data.data;
			setData(users);
			set_loading(false);

			const rows = response.data;
			console.log(rows);
            set_pagination({
                ...data,
                total: rows.total
            });
        }catch(error){
			set_loading(false);
            console.log(error);
        }
	}

	const filter = async () => {
		set_loading(true);
		try{
            const response = await api.get('/tutores', {
				params: { 
					search,
					tipo
				}
			});
            const users = response.data.data;
			setData(users);
			set_loading(false);
        }catch(error){
			set_loading(false);
            console.log(error);
        }
	}

	const handle_search_input = (e) => {
		const value = e.target.value
		setSearch(value);
	}

	useEffect(() => {
		load();
	},[]);

	useEffect(() => {
		filter();
	},[search,tipo]);

	const showPopconfirmDelete = (id) => {
		Swal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/tutores/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	const destacar = async (id) => {
		set_loading(true);
		const Form = new FormData();
		Form.append('id',id)
		try{
            const response = await api.post('/tutores/destacar',Form);
			messageApi.open({
				type: 'success',
				content: response.data.message,
				className: 'custom-class',
				style: {
					marginTop: '20vh',
				},
			});
			load();
			set_loading(false);
        }catch(error){
            console.log(error);
			set_loading(false);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
			render: (text) => <Link to={''}>{text}</Link>,
		},
        {
          title: 'Nome',
          dataIndex: 'nome',
          key: 'nome',
        },
		{
			title: 'Apelido',
			dataIndex: 'apelido',
			key: 'apelido',
		},
        {
			title: 'email',
			dataIndex: 'email',
			key: 'email',
		},
        {
          title: 'contacto',
          dataIndex: 'contacto',
          key: 'contacto',
        },
		{
			title: 'tipo',
			dataIndex: 'type',
			key: 'type',
		},
        {
          title: 'Ação',
          key: 'action',
          render: (row) => (
            <Space size="middle">
                <Link to={`/admin/tutores/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
				<Button onClick={() => showPopconfirmDelete(row.id)} danger shape="circle">
					<DeleteOutlined />
				</Button>
				{ row.destaque == 1 ? 
					<Button onClick={() => destacar(row.id)} className='bg-succuss-sflix text-white'>
						Remover destaque
					</Button> : <Button onClick={() => destacar(row.id)} className='bg-succuss-sflix text-white'>
						Destacar
					</Button> 
				}
            </Space>
          ),
        },
    ];

	const handleTableChange = (pagination, filters, sorter) => {
        load(pagination);
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== pagination.pageSize) {
          setData([]);
        }
    };

    return (
        <div className='bg-white m-5 p-7'>
			{contextHolder}
            <div className='flex-ttp-items mb-4'>
				<h3 className='header-sflix text-dark mb-3'>Tutores</h3>
                <Link to={`/admin/tutores/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <div className='mb-4'>
                <Row gutter={8}>
                    <Col span={8}>
                        <Input 
							onChange={handle_search_input} 
							placeholder="Pesquisar"
						/>
                    </Col>
                </Row>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
				onChange={handleTableChange}
				///rowKey={(record) => console.log(record)}
				pagination={pagination}
				loading={loading}
            />
        </div>
    )
}

export default Tutores;
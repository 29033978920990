import React, { useEffect, useState } from 'react'
import { Button, Space, Table, Tag, message } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import api from '../../../services/api';

const PaymentMethods = () => {
	const MySwal = withReactContent(Swal);
	const [messageApi, contextHolder] = message.useMessage();
	const [data,setData] = useState([]);

	const load = async () => {
		try{
			const response_types = await api.get('/payment/methods');
			const types = response_types.data.data;
			setData(types);
        }catch(error){
            console.log(error);
        }
	}

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/payment/method/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	const showPopconfirmDelete = (id) => {
		MySwal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

    const columns = [
        {
            title: '',
            render: (row) => 
            (
                <div style={{width: 40,height: 40,backgroundColor: '#eeeeee',borderRadius: 100, overflow: 'hidden'}}>
					<img src={row.image} style={{width: '100%', height: '100%', objectFit: 'cover'}}></img>
                </div>
            ),
        },
        {
          title: 'Nome do banco',
          dataIndex: 'nome',
          key: 'nome'
        },
        {
          title: 'tipo',
          dataIndex: 'tipo',
          key: 'tipo',
        },
        {
          title: 'Tipo de Integrações',
          dataIndex: 'integration',
          key: 'integration',
        },
		{
			title: 'Conta',
			dataIndex: 'payment_account',
			key: 'payment_account',
		},
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
			  if(row){
				  return (
					  <Tag color={'green'}>
						  Activo
					  </Tag>
				  );
			  }else{
				  return (
					  <Tag color={'volcano'}>
							Inactivo
					  </Tag>
				  );
			  }
			}
		},
        {
          title: 'Action',
          key: 'action',
          render: (row) => (
            <Space size="middle">
				<Link to={`/admin/settings/payments/methods/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
				<Button onClick={() => showPopconfirmDelete(row.id)} danger shape="circle">
					<DeleteOutlined />
				</Button>
            </Space>
          ),
        },
    ];

	useEffect(() => {
		load();
	},[]);

    return (
        <div className='bg-white m-5 p-7'>
			{contextHolder}
            <div className='flex-ttp-items mb-4'>
                <h3 className='header-sflix text-dark'>Métodos de Pagamentos</h3>
                <Link to={`/admin/settings/payments/methods/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
            />
        </div>
    )
}

export default PaymentMethods;
import React, { useEffect, useState } from 'react'
import { 
    message, 
    Select,
    Col,
    Row,
    Button,
    Tag,
    Space,
    Table
} from 'antd';
import {
    Label
} from "reactstrap";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';

import api from '../../../services/api';
import { useRef } from 'react';
import image from '../../../assets/images/imagebg.png';
import { Link } from 'react-router-dom';

const General = () => {
	const MySwal = withReactContent(Swal);
	const [messageApi, contextHolder] = message.useMessage();
    const [loading,setloading] = useState();
    const [moedas,setMoedas] = useState([]);
    const [defaultMoeda,setDefaultMoeda] = useState('');
    const [imageUrl, setImageUrl] = useState(image);
    const [imageUrl2, setImageUrl2] = useState(image);
    const [imageFile,setImageFile] = useState(null);
    const [load_slide_text,set_load_slide_text] = useState(false);
    const [slide_text_data,set_slide_text_data] = useState([]);
    const [configs,set_configs] = useState({});

    const imageRef = useRef();
    const imageRef2 = useRef();

    const load_slide_texts = async () => {
		try{
			const response_types = await api.get('/library/settings/slide-text');
			const data = response_types.data.data;
            set_slide_text_data(data);
			set_load_slide_text(false);
        }catch(error){
			set_load_slide_text(false);
            console.log(error);
        }
	}

	const handleDelete = async (id) => {
		set_load_slide_text(true);
		try{
            const response = await api.delete(`/library/settings/slide-text/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            load_slide_texts();
			set_load_slide_text(false);
        }catch(error){
			set_load_slide_text(false);
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const columns = [
		{
			title: 'Id',
			dataIndex: 'id',
			key: 'id',
		},
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Ação',
          key: 'action',
          render: (row) => (
            <Space size="middle">
              	<Link to={`/admin/settings/general/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
				<Button danger shape="circle" onClick={() => handleDelete(row.id)}>
					<DeleteOutlined />
				</Button>
            </Space>
			
          ),
        },
    ];

	useEffect(() => {
		load_slide_texts();
	},[]);

	const load = async () => {
		try{
            const response = await api.get("/settings");
			const response_currency = await api.get('/currencies');
            const currency = response_currency.data.data;
            const settings = response.data.data;

            setImageUrl(settings.imagem);
            setImageUrl2(settings.imagem2);
            set_configs(settings);
            setMoedas(currency.map((item) => ({
                value: item.id,
                label: item.name
            })));

            currency.map(item => {
                if(item.default){
                    setDefaultMoeda(item.id);
                }
            });
        }catch(error){
            console.log(error);
        }
	}

    const handle_currency = async (id) => {
        setDefaultMoeda(id);
        const Form = new FormData();
        Form.append('currency',id)
		try{
            const response = await api.patch('/currency/update',Form);

            console.log(response.data);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = async (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageUrl(url);
        });

        const Form = new FormData();
        Form.append("type",1);
        Form.append('image',file[0]);

		try{
            const response = await api.patch('/settings/set-image',Form);

            console.log(response.data);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    };

    const handleChangeImage2  = async (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageUrl2(url);
        });
        const Form = new FormData();
        Form.append("type",2);
        Form.append('image',file[0]);

		try{
            const response = await api.patch('/settings/set-image',Form);

            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }catch(error){
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    };

	useEffect(() => {
		load();
	},[]);

    return (
        <div className='bg-white m-5 p-7'>
			{contextHolder}
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            <h3 className='header-sflix text-dark mb-3'>Configurações</h3>
            <div className='mt-3'>
                <Label className=''>Moeda</Label>
                <Select
                    placeholder=""
                    style={{ width: '100%' }}
                    className="mt-1"
                    onChange={handle_currency}
                    value={defaultMoeda}
                    options={moedas}
                />
            </div>

            <div className='mt-5'>
                <Row gutter={12}>
                    <Col sm={8}>
                        <div className="w-full"> {/* conta corporativa*/}
                            <Label className='d-block'>Imagem banner</Label>
                            <div onClick={() => imageRef.current.click()} className='video-background mt-2 mb-2'>
                                <img src={imageUrl}></img>
                                <input hidden type={'file'} onChange={handleChangeImage} ref={imageRef}></input>
                            </div>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <div className="w-full"> {/* conta corporativa*/}
                            <Label className='d-block'>Imagem banner 2</Label>
                            <div onClick={() => imageRef2.current.click()} className='video-background mt-2 mb-2'>
                                <img src={imageUrl2}></img>
                                <input hidden type={'file'} onChange={handleChangeImage2} ref={imageRef2}></input>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='bg-white' style={{position: 'relative'}}>
                {contextHolder}
                { loading ? <div className='loader-flix-cont'>
                    <div
                        className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span
                        className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span>
                    </div>
                </div> : null }
                <div className='flex-ttp-items mb-4'>
                    <h3 className='header-sflix text-dark'>Textos em Slides</h3>
                    <Link to={`/admin/settings/general/create`}>
                        <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                    </Link>
                </div>
                <Table 
                    columns={columns} 
                    dataSource={slide_text_data} 
                />
            </div>
        </div>
    )
}

export default General;
import React, { useEffect, useRef, useState } from 'react'
import {
    Label
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    message
} from 'antd';

import { useParams } from 'react-router';
import image from '../../../assets/images/imagebg.png';
import api from '../../../services/api';

const { TextArea } = Input;

const FaculdadesEdit = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [paymentTypes,setPaymentTypes] = useState([]);
    const [imageFile,setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(image);
    const [loading,setloading] = useState();

    const params = useParams();
    const id = params.id;

    const load = async () => {
        try{
            const response = await api.get(`/settings/faculdades/${id}`);
            const payment = response.data.data;
            console.log(payment);
            form.setFieldsValue({
                name: payment.nome
            });
            form.setFieldsValue({
                descricao: payment.descricao
            });
        }catch(error){
            console.log(error);
        }
    }

    const onFinish = async (values) => {
        setloading(true);
        const Form = new FormData();
        Form.append('id',id);
        Form.append('name',values.name);
        Form.append('descricao',values.descricao);

        try{
            const response = await api.patch('/settings/faculdades/edit', Form);
            const data = response.data.data;
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    useEffect(() => {
        load();
    },[]);

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Editar</h3>
            <Form
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Título</Label>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Título!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Descrição!',
                                        }
                                    ]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default FaculdadesEdit;

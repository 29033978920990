import React, { useEffect, useRef, useState } from 'react'
import {
    Label
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    message,
    Upload,
    Progress,
} from 'antd';

import api from '../../services/api';
import image from '../../assets/images/imagebg.png';
import { useNavigate } from "react-router-dom";
import { InboxOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { BsFillEyeFill } from "react-icons/bs";

const { TextArea } = Input;
const { Dragger } = Upload;

const LibraryBookView = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [imageFile,setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(image);
    const [loading,setloading] = useState();
    const [categorias,set_cats] = useState([]);
    const [categoria,set_categoria] = useState([]);
    const [sub_categoria,set_sub_categoria] = useState([]);
    const [tipos,set_tipos] = useState([]);
    const [pages,set_pages] = useState("");
    const [file,set_file] = useState("");
    const [percentage,set_percentage] = useState(0);
    const [imagem,set_imagem] = useState("");
    const [file_url,set_file_url] = useState("");

    const params = useParams();
    const id = params.id;

    const [FormState, SetFormState] = useState({
        FormSubmitting: false,
        SingleCertFileUploading: false,
    });
    const [fileList, setFileList] = useState([]);

    let navigate = useNavigate();

    const imageRef = useRef();

    const load = async () => {
        setloading(true);
        try{
            const response_book = await api.get(`/library/book/${id}`);
            const response = await api.get('/library/categorias');
            const response_types = await api.get('/library/tipos');
            const book = response_book.data.data;
            const types = response_types.data.data;
			const cats = response.data.data;
            const data_categorias = [];

            cats.map((item) => {
                if(!item.parent_id){
                    data_categorias.push({
                        value: item.id,
                        label: item.name,
                        parent_id: item.parent_id
                    });
                }
            });
            set_tipos(types.map((item) => ({
                value: item.id,
                label: item.name,
            })));

			set_categoria(data_categorias);
            set_cats(cats);
            setImageUrl(book.imagem);
            set_file_url(book.file);

            form.setFieldsValue({
                title: book.title
            });
            form.setFieldsValue({
                author: book.author
            });
            form.setFieldsValue({
                publisher: book.publisher
            });
            form.setFieldsValue({
                descricao: book.descricao
            });
            form.setFieldsValue({
                type: book.type
            });
            form.setFieldsValue({
                visibilidade: book.status
            });
            form.setFieldsValue({
                download: book.download
            });
            form.setFieldsValue({
                categoria: book.category_id
            });
            form.setFieldsValue({
                sub_categoria: book.sub_category_id
            });

            setloading(false);
        }catch(error){
            setloading(false);
            console.log(error);
        }
    }
    
    useEffect(() => {
        load();
    },[]);

    const handleChange = (value) => {
        ///console.log(`selected ${value}`);
    };

    const onFinish = async (values) => {
        //if(!file) return message.error('O arquivo não foi enviado!');
        setloading(true);
        const Form = new FormData();
        Form.append('id',id);
        Form.append('title',values.title);
        Form.append('author',values.author);
        Form.append('publisher',values.publisher);
        Form.append('descricao',values.descricao);
        Form.append('visibilidade',values.visibilidade);
        Form.append('categoria',values.categoria);
        Form.append('sub_categoria',values.sub_categoria ? values.sub_categoria : "");
        Form.append('pages',pages);
        Form.append('file',file);
        Form.append('type',values.type ? values.type : "");
        Form.append('download',values.download);

        if(imageFile){
            Form.append('image',imageFile[0]);
            values.image = imageFile[0];
        }

        try{
            const response = await api.patch('/library/book/update', Form);
            const data = response.data.data;
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    const upload = async (e) => {
        const file = e.file;
        const Form = new FormData();
        Form.append('id',id);
        Form.append('file',file);
        try{
            const config = {
                onUploadProgress: progressEvent => {
                    const progress = progressEvent.loaded / progressEvent.total * 100;
                    set_percentage(parseInt(progress));
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const response = await api.post('/library/book.upload', Form, config);
            const data = response.data.data;
            set_file(data.file);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
                duration: 10
            });
        }catch(error){
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }
    
    const props = {
        name: 'file',
        multiple: false,
        customRequest: upload,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            console.log(info.file, info.fileList);
            }
            if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
        progress: {
            strokeWidth: 5,
            showInfo: true,
        },
    };

    const handle_categoria = (v) => {
        const data_categorias = [];
        categorias.map((item) => {
            if(item.parent_id == v){
                data_categorias.push({
                    value: item.id,
                    label: item.name
                });
            }
        });
        set_sub_categoria(data_categorias);
    }

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Editar Livro</h3>
            <Form
                autoComplete="off"
                form={form}
                className="flix-form-vds"
            >
                <Row>
                    <Col span={16}>
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Título</Label>
                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <Input disabled={true}/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Author</Label>
                                <Form.Item
                                    name="author"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your author!',
                                        }
                                    ]}
                                >
                                    <Input disabled={true}/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Publisher</Label>
                                <Form.Item
                                    name="publisher"
                                    rules={[
                                        {
                                            //required: true,
                                            message: 'Please input your publisher!',
                                        }
                                    ]}
                                >
                                    <Input disabled={true}/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input your Descrição!',
                                        }
                                    ]}
                                >
                                    <TextArea disabled={true}rows={4} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Tipo</Label>
                                <Form.Item
                                    name="type"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input your tipo!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        options={tipos}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Categoria</Label>
                                <Form.Item
                                    name="categoria"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your categoria!',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        onChange={handle_categoria}
                                        options={categoria}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Sub Categoria</Label>
                                <Form.Item
                                    name="sub_categoria"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please input your sub_categoria!',
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                        filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                        }
                                        options={sub_categoria}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Download</Label>
                                <Form.Item
                                    name="download"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your download!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Activo' },
                                            { value: 0, label: 'Inactivo' }
                                        ]}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Visibilidade</Label>
                                <Form.Item
                                    name="visibilidade"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your Visibilidade!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Activo' },
                                            { value: 0, label: 'Inactivo' }
                                        ]}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row className='flex-col pl-4'>
                            <div className="w-full"> {/* conta corporativa*/}
                                <Label className='d-block'>Imagem</Label>
                                <div onClick={() => imageRef.current.click()} className='video-background mt-2 mb-2'>
                                    <img src={imageUrl}></img>
                                    <input hidden type={'file'} onChange={handleChangeImage} ref={imageRef}></input>
                                </div>
                            </div>
                            {file_url ? <div className="w-full mt-2"> {/* conta corporativa*/}
                                <Label className='d-block'>Arquivo enviado</Label>
                                <div>
                                    <a href={file_url} target="_blank">
                                        <Button className='mt-2 bg-succuss-sflix' style={{display: "flex", alignItems: "center", gap: 5,width: "100%", justifyContent: "center"}} type="primary" success>
                                            <BsFillEyeFill size={15}/>
                                            <span>Visualizar Arquivo</span>
                                        </Button>
                                    </a>
                                </div>
                            </div> : null}
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default LibraryBookView;

import React from 'react'
import Footer from '../layouts/Footer'
import { Navbar } from '../layouts/Navbar'

export const ErrorPage = () => {
  return (
    <div className='bg-succuss-sflix'>
		<div className='not-found pt-10 container mx-auto' style={{height: '85vh'}}>
			<div className='h-full flex justify-center items-center flex-col'>
				<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
				width="150.000000pt" height="150.000000pt" viewBox="0 0 512.000000 512.000000"
				preserveAspectRatio="xMidYMid meet">
					<metadata>
					Created by potrace 1.16, written by Peter Selinger 2001-2019
					</metadata>
					<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
					fill="#fff" stroke="none">
					<path d="M1790 5109 c-440 -56 -856 -263 -1175 -583 -207 -208 -358 -439 -460
					-706 -198 -513 -171 -1128 70 -1610 314 -628 898 -1044 1585 -1129 109 -14
					371 -14 480 -1 202 25 425 87 590 163 l75 35 595 -592 c633 -630 633 -630 769
					-670 68 -20 217 -21 290 -1 101 27 162 65 268 168 147 143 192 222 213 369 16
					111 -1 215 -51 318 -41 84 -47 90 -624 670 l-582 585 48 95 c58 115 130 327
					158 470 115 584 -18 1163 -379 1641 -80 105 -270 295 -375 375 -280 213 -601
					348 -946 398 -115 17 -433 20 -549 5z m475 -390 c585 -69 1119 -496 1323
					-1059 356 -982 -267 -2028 -1300 -2185 -122 -19 -354 -19 -473 -1 -353 54
					-656 206 -909 454 -594 584 -660 1493 -155 2162 133 177 345 354 549 459 307
					158 610 211 965 170z m2437 -4051 c20 -66 4 -103 -80 -189 -85 -85 -123 -103
					-188 -89 -32 7 -118 89 -586 557 l-548 548 42 34 c24 19 93 84 153 146 l110
					113 543 -541 c427 -427 545 -550 554 -579z"/>
					<path d="M1438 3841 c-99 -32 -156 -138 -128 -241 10 -39 40 -74 241 -275
					l229 -230 -225 -225 c-247 -247 -263 -270 -251 -357 16 -121 130 -194 248
					-159 49 14 71 33 278 241 l225 225 225 -224 c124 -123 236 -230 250 -237 14
					-7 50 -12 81 -12 134 0 223 120 184 248 -13 45 -38 74 -240 275 l-225 224 229
					231 c217 219 229 232 239 280 32 151 -92 274 -238 235 -39 -10 -74 -40 -275
					-241 l-230 -229 -220 221 c-121 121 -233 227 -248 235 -35 19 -113 26 -149 15z"/>
					</g>
				</svg>
				<h1 className='text-white text-4xl bold mt-5'>Not Found</h1>
				<p className='text-white mt-2'>A pagina nao foi encontrada</p>
			</div>
		</div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { 
    Button, 
    Space, 
    Table, 
    Tag,
    Row,
    Col,
    Input,
	message
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import api from '../../../services/api';
import { useParams } from 'react-router';

import Swal from 'sweetalert2';

const QuestionsAnswers = () => {
	const [data,setData] = useState();
	const [search,setSearch] = useState('');
	const [messageApi, contextHolder] = message.useMessage();
	const [index,setIndex] = useState(1);
	const [loading,setLoading] = useState(true);

	const params = useParams();
	const id = params.id;

	const load = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/quiz/questions/answers/${id}`, {
				params: { 
					search,
					index
				}
			});
			setLoading(false);
			setData(response.data.data);
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	useEffect(() => {
		load();
	},[search]);

    const showPopconfirmDelete = (id) => {
		Swal.fire({
			title: 'Tem certeza?',
			text: "Você não será capaz de reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: 'green',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok'
		}).then((result) => {
			if (result.isConfirmed) {
				handleDelete(id);
			}
		})
	};

	const handleDelete = async (id) => {
		try{
            const response = await api.delete(`/quiz/answer/delete/${id}`);
			messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
			load();
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const columns = [
        {
          title: 'Título',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'Pergunta',
          dataIndex: 'questions',
          key: 'questions',
        },
        {
          title: 'Resposta correcta',
          key: 'correct',
          dataIndex: 'correct',
          render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        },
        {
          title: 'Action',
          key: 'action',
          render: (row) => (
            <Space size="middle">
                <Link to={`/admin/cursos/quiz/question/${id}/answers/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
                <Button danger onClick={() => showPopconfirmDelete(row.id)} shape="circle">
                    <DeleteOutlined />
                </Button>
            </Space>
          ),
        },
    ];
    

    return (
        <div className='bg-white m-5 p-7'>
            {contextHolder}
			{ loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            <div className='flex-ttp-items mb-4'>
                <h3 className='header-sflix text-dark'>Repostas</h3>
                <Link to={`/admin/cursos/quiz/questions/${id}/answers/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <div className='mt-3 mb-4'>
                <Row gutter={8}>
                    <Col span={8}>
                        <Input placeholder="Pesquisar" />
                    </Col>
                </Row>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
            />
        </div>
    )
}

export default QuestionsAnswers;
import React, { useEffect, useState } from 'react'
import { 
    Card, 
    Button, 
    Space, 
    Table, 
    Tag,
    Select,
    Row,
    Col,
    Input
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    FolderViewOutlined
} from '@ant-design/icons';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';

import api from '../../services/api';
import { FormatCurrency } from '../../utils/FormatCurrency';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const Pagamentos = () => {
	const [data,setData] = useState([]);
	const [search,setSearch] = useState('');
	const [index,setIndex] = useState(1);
	const [loading,setLoading] = useState(false);
	const [payment_num_monthly,set_payment_num_monthly] = useState(0);
	const [payment_monthly,set_payment_monthly] = useState(0);
	const [total_payments,set_total_payments] = useState(0);
	const [data_lines,set_data_lines] = useState({
        labels: [],
        datasets: [
			{
				label: 'Subscrições',
				data: [],
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			}
        ]
    });

	const load = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/pagamentos/logs`, {
				params: { 
					search,
					index,
				}
			});
			const relatorio_response = await api.get('/relatorios/pagamentos');
            const logs = response.data.data;
			const relatorio = relatorio_response.data.data;

			let graph_data_lines = {...data_lines};
			graph_data_lines.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Ago','Sept','Out','Nov','Dez']
			graph_data_lines.datasets[0].data = relatorio.graph.data_by_month;
			set_data_lines(graph_data_lines);
			set_payment_num_monthly(relatorio.count_monthly);
			set_payment_monthly(relatorio.total_monthly);
			set_total_payments(relatorio.total_payments);

			setData(logs);
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
        }
	}

	const filter = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/pagamentos/logs`, {
				params: { 
					search,
					index,
				}
			});
            const logs = response.data.data;
			setData(logs);
			setLoading(false);
        }catch(error){
			setLoading(false);
            console.log(error);
        }
	}

	useEffect(() => {
		load();
	},[]);

	useEffect(() => {
		filter();
	},[search,index]);

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Vendas',
          },
        },
    };

	const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
          title: 'Usuario',
          key: 'usuario',
		  render: (row) => <span>{row.nome} {row.apelido}</span>
        },
        {
            title: 'Tipo pagamento',
            dataIndex: 'payment_type',
            key: 'payment_type',
        },
		{
			title: 'Pacote',
			dataIndex: 'pacote',
			key: 'pacote',
		  },
        {
            title: 'Duração(dias)',
            dataIndex: 'duracao',
            key: 'duracao',
        },
		{
			title: 'Valor',
			key: 'preco',
			render: (row) => <span>{FormatCurrency(row.preco)}</span>
		},
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Pago
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Não Pago
						</Tag>
					);
				}
			}
        }
    ];
    
    return (
        <div>
            <div className='m-5'>
                <div className='plaform-cards'>
                    <div className='card-flix-tp'>
                        <div className='card-inner-cont-fl'>
                            <div className='card-f-left'>
                                <div className='text-ttl-card'>Numéro de pagamentos este mês</div>
                            </div>
                            <div className='card-f-right'>
                                <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{FormatCurrency(payment_num_monthly)}</h3>
                            </div>
                        </div>
                        <div className='mt-5 mb-3' style={{height: 5, backgroundColor: '#0fa966'}}></div>
                    </div>
                    <div className='card-flix-tp'>
                        <div className='card-inner-cont-fl'>
                            <div className='card-f-left'>
                                <div className='text-ttl-card'>Pagamentos este mês</div>
                            </div>
                            <div className='card-f-right'>
                                <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{FormatCurrency(payment_monthly)}</h3>
                            </div>
                        </div>
                        <div className='mt-5 mb-3' style={{height: 5, backgroundColor: '#0fa966'}}></div>
                    </div>
                    <div className='card-flix-tp'>
                        <div className='card-inner-cont-fl'>
                            <div className='card-f-left'>
                                <div className='text-ttl-card'>Total de Pagamentos</div>
                            </div>
                            <div className='card-f-right'>
                                <h3 className='hight-ttl-card' style={{color: '#0fa966'}}>{FormatCurrency(total_payments)}</h3>
                            </div>
                        </div>
                        <div className='mt-5 mb-3' style={{height: 5, backgroundColor: '#0fa966'}}></div>
                    </div>
                </div>
            </div>

            <div className='m-5'>
                <div className='graph-card-item'>
                    { data_lines.datasets[0].data.length ? <Line height={100} options={options} data={data_lines} /> : null }
                </div>
            </div>

            <div className='bg-white m-5 p-7'>
                <h3 className='header-sflix text-dark mb-3'>Pagamentos</h3>
                <div className='mt-3 mb-4'>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Input onChange={(e) => setSearch(e.target.value)} placeholder="Pesquisar" />
                        </Col>
                    </Row>
                </div>
                <Table 
                    columns={columns} 
                    dataSource={data} 
                />
            </div>
        </div>
    )
}

export default Pagamentos;
import React, { useState } from 'react'
import {
    Label
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Form,
    message
} from 'antd';
import api from '../../../services/api';

const { TextArea } = Input;

const PacotesCreate = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading,setloading] = useState();

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinish = async (values) => {
        console.log('finish',values)
        setloading(true);
        const Form = new FormData();
        Form.append('nome',values.nome);
        Form.append('preco',values.preco);
        Form.append('duracao',values.duracao);
        Form.append('descricao',values.descricao);

        try{
            const response = await api.post('/pacotes/create', Form);
            const data = response.data.data;
            console.log(data);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    return (
        <div className='bg-white m-5 p-7'>
            {contextHolder}
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            <h3 className='header-sflix text-dark mb-3'>Criar Pacote</h3>
            <Form
                onFinish={onFinish}
                form={form}
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Nome</Label>
                                <Form.Item
                                    name="nome"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your nome!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Preço</Label>
                                <Form.Item
                                    name="preco"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Preço!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full mt-3">
                                <Label className=''>Duração</Label>
                                <Form.Item
                                    name="duracao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your Tipo!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: '0', label: 'Gratis' },
                                            { value: '30', label: '1 mes' },
                                            { value: '60', label: '2 meses' },
                                            { value: '90', label: '3 meses' },
                                            { value: '365', label: '1 ano' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Descrição!',
                                        }
                                    ]}
                                >
                                    <TextArea rows={4} />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default PacotesCreate;

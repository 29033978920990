import React, { useEffect, useRef, useState } from 'react'
import {
    Label,
    FormGroup
} from "reactstrap";

import { 
    Button,
    Col,
    Input,
    Row,
    Select,
    Upload,
    Spin,
    Modal,
    Progress,
    message,
    Form,
    DatePicker
} from 'antd';
import { InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { Link, useNavigate } from "react-router-dom";
import { RcFile, UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import api from '../../services/api';

import { useParams } from 'react-router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(customParseFormat);

const dateFormat = 'YYYY/MM/DD';

const { TextArea } = Input;

const antIcon = (
<LoadingOutlined
    style={{
    fontSize: 24,
    }}
    spin
/>
);

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

const VideoPodcastView = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [cursos,setCursos] = useState([]);
    const [curso,setCurso] = useState('');
    const [loading,setloading] = useState(true);
    const [uploading,setUploading] = useState(false);
    const [percentage,setPercentage] = useState(0);
    const [video_info,set_video_info] = useState({});
    const [imageUrl,setImageUrl] = useState('');
    const [imageFile,setImageFile] = useState(null);
    const [video_file,set_video_file] = useState('');
    const [categorias,set_categorias] = useState([]);
    const [date,set_date] = useState(new Date);

    const params = useParams();

    const id = params.id;
    const video_id = params.video;

    const fileRef = useRef();

    const load = async () => {
        try{
			const response_video = await api.get(`/podcast/video.uploaded/${id}`);
            const response_types = await api.get('/podcast/category');
            const video_data = response_video.data.data;
            const cats = response_types.data.data;
            set_categorias(cats.map((item) => ({
				value: item.id,
				label: item.name
			})));
            console.log(video_data);
            if(video_data){
                form.setFieldsValue({
                    date: dayjs(video_data.date)
                });
                form.setFieldsValue({
                    title: video_data.title
                });
                form.setFieldsValue({
                    descricao: video_data.descricao
                });
                form.setFieldsValue({
                    category: video_data.category
                });
                form.setFieldsValue({
                    visibilidade: video_data.status
                });
            }
            set_video_file(video_data.video_arquive);
            setloading(false);
        }catch(error){
            console.log(error);
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinish = async (values) => {
        if(!video_info.file_name){
            return messageApi.open({
                type: 'error',
                content: 'Selecione um video!',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }else if(!(video_info.frame && video_info.frame.path)){
            return messageApi.open({
                type: 'error',
                content: 'Selecione uma miniatura para o video!',
                className: 'custom-class',
                style: {
                    marginTop: '20vh'
                }
            });
        }
        const Form = new FormData();
        Form.append('title',values.title);
        Form.append('descricao',values.descricao);
        Form.append('curso',id);
        Form.append('visibilidade', values.visibilidade);
        Form.append('video_info', JSON.stringify(video_info));

        if(imageFile){
            Form.append('image',imageFile[0]);
            values.image = imageFile[0];
        }

        try{
            const response = await api.post('/curso/module.create', Form);
            const data = response.data.data;
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        const file = info.target.files;
        if(!beforeUpload(file[0])){
            return true;
        }
        getBase64(file[0], (url) => {
            setImageFile(file);
            setImageUrl(url);
        });
    };

    const upload_video = async (e) => {
        const file = e.target.files;
        const video_file = file[0];

        if(!video_file){
            return messageApi.open({
                type: 'error',
                content: 'Invalid',
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
        }

        const Form = new FormData();
        Form.append('id',id);
        Form.append('video',video_file);

        setUploading(true);

        try{
            const config = {
                onUploadProgress: progressEvent => {
                    const progress = progressEvent.loaded / progressEvent.total * 100;
                    setPercentage(parseInt(progress));
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const response = await api.post('/cursos/video.upload', Form, config);
            const data = response.data.data;
            console.log(response.data.data);

            set_video_info(response.data.data);
            setImageUrl(data.frame.path);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
                duration: 10
            });
            setUploading(false);
        }catch(error){
            setUploading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    useEffect(() => {
        load();
    },[]);

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span>
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Podcast</h3>
            <Form
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Título</Label>
                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <Input disabled={true}/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Descrição</Label>
                                <Form.Item
                                    name="descricao"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <TextArea disabled={true} rows={4} />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Categoria</Label>
                                <Form.Item
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Categoria!',
                                        }
                                    ]}
                                >
                                    <Select
                                        disabled={true}
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        options={categorias}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Data</Label>
                                <Form.Item
                                    name="date"
                                >
                                    <DatePicker 
                                        style={{width: "100%"}}
                                        disabled={true}
                                    />
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Visibilidade</Label>
                                <Form.Item
                                    name="visibilidade"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Visibilidade!',
                                        }
                                    ]}
                                >
                                    <Select
                                        disabled={true}
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        onChange={handleChange}
                                        options={[
                                            { value: 1, label: 'Activo' },
                                            { value: 0, label: 'Inactivo' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            {
                                /*
                                    <div className="w-full mb-3">
                                        <div className='mt-3 mb-2'>
                                            <Label className='d-block'>Miniaturas</Label>
                                        </div>
                                        <Upload
                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                            listType="picture-circle"
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange_image}
                                        >
                                        {fileList.length >= 8 ? null : uploadButton}
                                        </Upload>
                                        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                                            <img
                                            alt="example"
                                            style={{
                                                width: '100%',
                                            }}
                                            src={previewImage}
                                            />
                                        </Modal>
                                    </div>
                                */
                            }
                        </Row>
                    </div>
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div>
                                <div className="w-full mb-3"> {/* conta corporativa*/}
                                    <div className='video-background'>
                                        <video controls style={{width: '100%',height: '100%'}} src={video_file}></video>
                                        {
                                            /*
                                            <div onClick={() => ''} className='bold' style={{display: 'flex',justifyContent: 'center',alignItems: 'center',width: '100%', height: '100%', overflow: 'hidden'}}>
                                            { uploading
                                            ? 
                                            <Spin indicator={antIcon} /> 
                                            : 
                                            <div>
                                                {
                                                    imageUrl ? <img style={{width:'100%',height:'100%',objectFit: 'cover'}} src={imageUrl}/> : 'Video'
                                                }
                                            </div> }
                                        </div>
                                            */
                                        }
                                    </div>
                                    <input onChange={upload_video} hidden type={'file'} accept="video/mp4,video/x-m4v,video/*" ref={fileRef}/>
                                </div>
                                <div className=''>
                                    { percentage ? <Progress percent={percentage} /> : null }
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
                <Link to={`/admin/podcast/edit/${id}`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Editar</Button>
                </Link>
            </Form>
        </div>
    )
}

export default VideoPodcastView;

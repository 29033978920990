import { CheckCircleOutlined, CloseCircleOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Alert, Button, Flex, Modal, Select, Upload, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { apiCreateBulkUsers } from '../../../../services/http/users/post.service';

type UserType = {
    value: number;
    label: string;
};
type University = {
    created_at: string;
    descricao: string;
    id: number;
    nome: string;
    updated_at: string;
};
interface Props {
    isModalOpen: boolean;
    handleOk: () => void;
    handleCancel: () => void;
    universities: University[];
    userTypes: UserType[];
}
function CreateUsersThroughExcelModal({ handleCancel, userTypes, handleOk, isModalOpen, universities }: Props) {
    const [xlsFile, setXlsFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [xlsTojsonData, setXlsToJsonData] = useState('');
    const [selectedUniversity, setSelectedUniversity] = useState<undefined | number>();
    const [selectedUserType, setSelectedUserType] = useState<undefined | number>();
    const [usersEmails, setUsersEmails] = useState<string[]>([]);
    const [validatedSheetStructure, setValidatedSheetStructure] = useState({
        nome: false,
        apelido: false,
        email: false,
        contacto: false,
    });
    const [usersToCreate, setUsersToCreate] = useState<{ nome: string; apelido: string; email: string; contacto: string; user_type: string }[]>([]);
    const [messageApi, contextHolder] = message.useMessage();

    function validateStructure(arr: any[]) {
        const sample = arr[0];

        const validKeys = ['nome', 'apelido', 'email', 'contacto'];
        let correctValidations = 0;
        for (const key in sample) {
            if (validKeys.includes(key.toLowerCase())) {
                correctValidations += 1;
                setValidatedSheetStructure((prevState) => {
                    return {
                        ...prevState,
                        [key.toLowerCase()]: true,
                    };
                });
            }
        }

        if (correctValidations === validKeys.length) {
            const USERS_TO_CREATE: any[] = [];
            const USERS_EMAILS: string[] = [];
            arr.forEach((currentUser, index) => {
                USERS_TO_CREATE.push({
                    nome: currentUser['nome'] || currentUser['Nome'],
                    apelido: currentUser['apelido'] || currentUser['Apelido'],
                    contacto: currentUser['contacto'] || currentUser['Contacto'],
                    email: currentUser['email'] || currentUser['Email'],
                    user_type: String(selectedUserType),
                    password: 'demo123' + Math.random(),
                    faculdade: String(selectedUniversity),
                });
                USERS_EMAILS.push(currentUser['email'] || currentUser['Email']);
            });
            setUsersToCreate(USERS_TO_CREATE);
            setUsersEmails(USERS_EMAILS);
        }
    }

    async function handleBulkUserCreation() {
        if (!selectedUniversity) {
            messageApi.open({
                type: 'error',
                content: 'Selecione a faculdade',
                style: {
                    marginTop: '6vh',
                },
            });
        } else {
            setLoading(true);
            const response = await apiCreateBulkUsers({ users: usersToCreate, university: String(selectedUniversity), userEmails: usersEmails });

            if (response.status) {
                messageApi.open({
                    type: 'success',
                    content: response.message,
                });
                handleCancel();
            } else {
                messageApi.open({
                    type: 'error',
                    content: response.message,
                });
            }
            setLoading(false);
        }
    }

    function ExcelToJsonConverter(file: RcFile) {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setXlsToJsonData(JSON.stringify(json, null, 2));
                // console.log(Array.isArray(json))
                if (Array.isArray(json)) {
                    if (json.length) {
                        validateStructure(json);
                    }
                }
            };
            reader.readAsArrayBuffer(file);
        }
    }

    const handleUniversitiesChange = (value: number) => {
        setSelectedUniversity(value);
    };

    return (
        <>
            {contextHolder}
            <Modal
                title="Registo em massa"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Voltar
                    </Button>,
                    <Button loading={loading} key="submit" type="primary" className="bg-succuss-sflix" onClick={handleBulkUserCreation}>
                        {loading ? 'Processando...' : 'Criar'}
                    </Button>,
                ]}
            >
                {selectedUserType && selectedUniversity ? (
                    <Alert
                        message={<span className="font-semibold">Atenção</span>}
                        description={
                            <>
                                <p>A planilha teve seguir esta estrutura (no mínimo):</p>
                                <ul>
                                    <li>
                                        <Flex align="center" gap={10}>
                                            {xlsTojsonData ? validatedSheetStructure['nome'] ? <span className="text-xs">✅</span> : <span className="text-xs">❌</span> : null}
                                            <span>Nome</span>
                                        </Flex>
                                    </li>
                                    <li>
                                        <Flex align="center" gap={10}>
                                            {xlsTojsonData ? validatedSheetStructure['apelido'] ? <span className="text-xs">✅</span> : <span className="text-xs">❌</span> : null}
                                            <span>Apelido</span>
                                        </Flex>
                                    </li>
                                    <li>
                                        <Flex align="center" gap={10}>
                                            {xlsTojsonData ? validatedSheetStructure['email'] ? <span className="text-xs">✅</span> : <span className="text-xs">❌</span> : null}
                                            <span>Email</span>
                                        </Flex>
                                    </li>
                                    <li>
                                        <Flex align="center" gap={10}>
                                            {xlsTojsonData ? validatedSheetStructure['contacto'] ? <span className="text-xs">✅</span> : <span className="text-xs">❌</span> : null}
                                            <span>Contacto</span>
                                        </Flex>
                                    </li>
                                </ul>
                            </>
                        }
                        type="warning"
                    />
                ) : null}

                <div className="mb-3">
                    <span className="">Tipo de usuário: </span>
                    <Select placeholder="" style={{ width: '100%' }} className="" onChange={(value) => setSelectedUserType(value)} options={userTypes} />
                </div>
                <div className="mt-3">
                    <div>
                        <span className="">Faculdade: </span>
                        <Select
                            allowClear
                            placeholder=""
                            style={{ width: '100%' }}
                            className=""
                            onChange={handleUniversitiesChange}
                            options={universities.map((university) => {
                                return { value: university.id, label: university.nome };
                            })}
                        />
                    </div>
                </div>
                {selectedUserType && selectedUniversity ? (
                    <Upload
                        accept=".xls,.xlsx"
                        onRemove={() => {
                            setXlsFile(null);
                            setXlsToJsonData('');
                            setValidatedSheetStructure({
                                apelido: false,
                                contacto: false,
                                email: false,
                                nome: false,
                            });
                            setUsersToCreate([]);
                        }}
                        beforeUpload={(file) => {
                            ExcelToJsonConverter(file);
                            return false;
                        }}
                    >
                        <Button className="mt-5" icon={<FileExcelOutlined />}>
                            Selecionar planilha
                        </Button>
                    </Upload>
                ) : null}
            </Modal>
        </>
    );
}

export default CreateUsersThroughExcelModal;

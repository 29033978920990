import React, { useEffect, useState } from 'react'
import { Card, 
    Button, 
    Space, 
    Table, 
    Tag,
    Row,
    Col,
    Input,
    message
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useNavigate } from "react-router-dom";
import api from '../../../services/api';
import { useParams } from 'react-router';

const Quiz = () => {
	const [data,setData] = useState();
	const [search,setSearch] = useState('');
	const [messageApi, contextHolder] = message.useMessage();
	const [index,setIndex] = useState(1);
	const [loading,setLoading] = useState(true);

	const params = useParams();
	const id = params.id;
    const type = params.type;

	const load = async () => {
		setLoading(true);
		try{
            const response = await api.get(`/quiz/${type}/${id}`, {
				params: { 
					search,
					index
				}
			});
			setLoading(false);
			setData(response.data.data);
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

	useEffect(() => {
		load();
	},[search]);

    const columns = [
        {
          title: 'Titulo',
          dataIndex: 'title',
          key: 'title',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Tipo',
          dataIndex: 'tipo',
          key: 'tipo',
        },
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        },
        {
          title: 'Action',
          key: 'action',
          render: (row) => (
            <Space size="middle">
                <Link to={`/admin/cursos/quiz/${row.id}/questions`}>
                    <Button className='bg-succuss-sflix text-white'>
                        <svg style={{width: 22,height: 22}} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 96 960 960" width="48"><path fill='#fff' d="M479.825 860.065q-14.499 0-24.195-9.82-9.695-9.821-9.695-24.245V610.065H230q-14.424 0-24.245-9.871-9.82-9.871-9.82-24.369 0-14.499 9.82-24.195 9.821-9.695 24.245-9.695h215.935V326q0-14.424 9.871-24.245 9.871-9.82 24.369-9.82 14.499 0 24.195 9.82 9.695 9.821 9.695 24.245v215.935H730q14.424 0 24.245 9.871 9.82 9.871 9.82 24.369 0 14.499-9.82 24.195-9.821 9.695-24.245 9.695H514.065V826q0 14.424-9.871 24.245-9.871 9.82-24.369 9.82Z"/></svg>
                    </Button>
                </Link>
                <Link to={`/admin/cursos/${type}/quiz/edit/${row.id}`}>
					<Button shape="circle">
						<EditOutlined />
					</Button>
				</Link>
                <Button danger shape="circle">
                    <DeleteOutlined />
                </Button>
            </Space>
          ),
        },
    ];

    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
			{contextHolder}
			{ loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            <div className='flex-ttp-items mb-4'>
                <h3 className='header-sflix text-dark'>Quiz</h3>
                <Link to={`/admin/cursos/${type}/quiz/${id}/create`}>
                    <Button className='mt-4 bg-succuss-sflix' type="primary" success>Adicionar</Button>
                </Link>
            </div>
            <div className='mt-3 mb-4'>
                <Row gutter={8}>
                    <Col span={8}>
                        <Input onChange={(e) => setSearch(e.target.value)} placeholder="Pesquisar" />
                    </Col>
                </Row>
            </div>
            <Table 
                columns={columns} 
                dataSource={data} 
            />
        </div>
    )
}

export default Quiz;
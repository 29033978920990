import { forwardRef, useImperativeHandle, useState } from 'react'
import { 
    Button, 
    Modal, 
    Table, 
    Tag
 } from 'antd';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';
import { FormatCurrency } from '../../utils/FormatCurrency';
import api from '../../services/api';

const Subscricoes = forwardRef((props, ref) => {
    const [status, setStatus] = useState(false);
    const [id,setId] = useState('');
    const [loading,setLoading] = useState(false);
	const [data,setData] = useState([]);

    const open = (id) => {
        setStatus(true);
        setId(id);
		load(id);
    }

    const close = () => {
        setStatus(false);
    }
    
    const load = async (id) => {
        try{
            const pacote = await api.get(`/pacote/user/${id}`);
            setData(pacote.data.data);
        }catch(error){
            console.log(error);
        }
    }

    const columns = [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id'
        },
        {
            title: 'Tipo pagamento',
            dataIndex: 'payment_type',
            key: 'payment_type',
        },
		{
			title: 'Pacote',
			dataIndex: 'name',
			key: 'name',
		  },
        {
            title: 'Duração(dias)',
            dataIndex: 'duracao',
            key: 'duracao',
        },
		{
			title: 'Preço',
			key: 'preco',
			render: (row) => <span>{FormatCurrency(row.preco)}</span>
		},
        {
			title: 'Estado',
			key: 'status',
			dataIndex: 'status',
			render: (row) => {
				if(row){
					return (
						<Tag color={'green'}>
							Activo
						</Tag>
					);
				}else{
					return (
						<Tag color={'volcano'}>
							Inactivo
						</Tag>
					);
				}
			}
        }
    ];

    useImperativeHandle(ref, () => ({
        open,
        close
    }));

  return (
    <Modal
        centered
        open={status}
        onOk={() => setStatus(false)}
        onCancel={() => setStatus(false)}
        width={700}
        footer={[
            <div></div>
        ]}
        style={{
            padding: 0
        }}
        className=""
    >
        <div className=''>
            <h1 className='bold text-lg'>Subscrições do usuário</h1>
            <div className='pt-6 pb-6'>
                <Table 
					columns={columns} 
					dataSource={data} 
				/>
            </div>
        </div>
    </Modal>
  )
});

export default Subscricoes;
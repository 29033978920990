import React from 'react'
import LeftNav from './LeftNav';
import { Navbar } from './Navbar';

const LayoutMain = (props) => {
    const {children} = props;
    return (
        <>
            <div className='wrapper-container'>
                <div className='left-nav'>
                    <LeftNav/>
                </div>
                <div className='layout-wrapper-szn'>
                    <Navbar/>
                    <div className='main-container'>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LayoutMain

import React, { useEffect, useState } from 'react'
import {
    Label,
} from "reactstrap";

import { 
    Button,
    Input,
    Row,
    Select,
    message,
    Form
} from 'antd';

import { useNavigate } from "react-router-dom";
import api from '../../../services/api';
import { useParams } from 'react-router';

const { TextArea } = Input;

const AnswersCreate = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading,setloading] = useState();
    const [quiz_types,set_quiz_types] = useState([]);

    let navigate = useNavigate();
    const params = useParams();
	const id = params.id;
    const type = params.type;
    
    const load = async () => {
		try{
            const response = await api.get(`/quiz/types`);
            const data = response.data.data;
        }catch(error){
            console.log(error);
			const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
	}

    const onFinish = async (values) => {
        values.question_id = id;
        setloading(true);
        try{
            const response = await api.post(`/quiz/questions/answer/create`, values);
            messageApi.open({
                type: 'success',
                content: response.data.message,
                className: 'custom-class',
                style: {
                    marginTop: '20vh',
                },
            });
            setloading(false);
            navigate(`/admin/cursos/quiz/question/${id}/answers`);
        }catch(error){
            setloading(false);
            const error_response = error.response;
            if(typeof error_response !== 'undefined'){
                messageApi.open({
                    type: 'error',
                    content: error_response.data.message,
                    className: 'custom-class',
                    style: {
                        marginTop: '20vh',
                    },
                });
            }
        }
    }

    useEffect(() => {
        load();
    },[]);


    return (
        <div className='bg-white m-5 p-7' style={{position: 'relative'}}>
            { loading ? <div className='loader-flix-cont'>
                <div
                    className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                    className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                    >
                </div>
            </div> : null }
            {contextHolder}
            <h3 className='header-sflix text-dark mb-3'>Criar Respostas</h3>
            <Form
                onFinish={onFinish}
                autoComplete="off"
                form={form}
            >
                <div gutter={8} className="form-row-sflix">
                    <div sm={12} className="w-full">
                        <Row className='flex-col'>
                            <div className="w-full">
                                <Label className=''>Título</Label>
                                <Form.Item
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your title!',
                                        }
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </div>
                            <div className="w-full">
                                <Label className=''>Resposta Correcta?</Label>
                                <Form.Item
                                    name="correct"
                                    rules={
                                    [
                                        {
                                            required: true,
                                            message: 'Please input your resposta!',
                                        }
                                    ]}
                                >
                                    <Select
                                        placeholder=""
                                        style={{ width: '100%' }}
                                        className=""
                                        options={[
                                            { value: 1, label: 'Sim' },
                                            { value: 0, label: 'Nao' }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                        </Row>
                    </div>
                </div>
                <Button htmlType='submit' className='mt-4 bg-succuss-sflix' type="primary" success>Submit</Button>
            </Form>
        </div>
    )
}

export default AnswersCreate;

import CursoCreate from "../views/cursos/create";
import CursoEdit from "../views/cursos/create/edit";
import CursoView from "../views/cursos/create/view";
import Cursos from "../views/cursos/cursos";
import Quiz from "../views/cursos/quiz";
import AnswersCreate from "../views/cursos/quiz/answers_create";
import QuizCreate from "../views/cursos/quiz/create";
import QuizQuestions from "../views/cursos/quiz/questions";
import QuestionsAnswers from "../views/cursos/quiz/questions_answers";
import QuizQuestionsCreate from "../views/cursos/quiz/questions_create";
import VideosList from "../views/cursos/videos_list";
import Videos from "../views/cursos/videos";
import VideoUpload from "../views/cursos/videos/create";
import VideoUploadEdit from "../views/cursos/videos/edit";
import VideoUploadView from "../views/cursos/videos/view";
import HomePage from "../views/home";
import Login from "../views/login";
import Logout from "../views/login/logout";
import LogsLogin from "../views/logs/logins";
import Pagamentos from "../views/pagamentos";
import Subscricoes from "../views/pagamentos/subscricoes";
import General from "../views/settings/general";
import Pacotes from "../views/settings/Pacotes";
import PacotesCreate from "../views/settings/pacotes/PacotesCreate";
import PacotesEdit from "../views/settings/pacotes/PacotesEdit";
import PacotesSpecsCreate from "../views/settings/pacotes/PacotesSpecsCreate";
import PacotesSpecsEdit from "../views/settings/pacotes/PacotesSpecsEdit";
import PacotesSpecs from "../views/settings/pacotes/Specs";
import PaymentMethods from "../views/settings/payment_methods";
import PaymentMethodsCreate from "../views/settings/payment_methods/create";
import PaymentMethodsEdit from "../views/settings/payment_methods/edit";
import UserCreate from "../views/user/create";
import UserEdit from "../views/user/edit";
import UserTypes from "../views/user/types";
import Users from "../views/user/users";
import QuizEdit from "../views/cursos/quiz/edit";
import QuizQuestionsEdit from "../views/cursos/quiz/questions_edit";
import AnswersEdit from "../views/cursos/quiz/answers_edit";
import BookCategories from "../views/library/categorias";
import BookCategoriesCreate from "../views/library/categorias/create";
import BookCategoriesEdit from "../views/library/categorias/edit";
import Library from "../views/library";
import LibraryBookCreate from "../views/library/create";
import BookTipos from "../views/library/tipos";
import BookTiposCreate from "../views/library/tipos/create";
import BookTypesEdit from "../views/library/tipos/edit";
import LibraryBookEdit from "../views/library/edit";
import LibraryBookView from "../views/library/view";
import CoursesTipos from "../views/cursos/tipo";
import CoursesTiposCreate from "../views/cursos/tipo/create";
import CoursesTiposEdit from "../views/cursos/tipo/edit";
import PodCast from "../views/podcast";
import PodcastCategories from "../views/podcast/categorias";
import PodcastCategoriesCreate from "../views/podcast/categorias/create";
import PodcastCategoriesEdit from "../views/podcast/categorias/edit";
import VideoPodcastUpload from "../views/podcast/create";
import VideoPodcastView from "../views/podcast/view";
import VideoPodcastEdit from "../views/podcast/edit";
import Webinars from "../views/webinars";
import WebinarsCreate from "../views/webinars/create";
import WebinarsView from "../views/webinars/view";
import WebinarsEdit from "../views/webinars/edit";
import SlideTextCreate from "../views/settings/general/slide_text/create";
import SlideTextEdit from "../views/settings/general/slide_text/edit";
import Tutores from "../views/tutores/tutores";
import TutoresCreate from "../views/tutores/create";
import TutoresEdit from "../views/tutores/edit";
import VideosLinks from "../views/settings/videos";
import VideosLinksCreate from "../views/settings/videos/create";
import VideosLinksEdit from "../views/settings/videos/edit";
import Faculdades from "../views/settings/faculdades";
import FaculdadesCreate from "../views/settings/faculdades/create";
import FaculdadesEdit from "../views/settings/faculdades/edit";

const RoutesList = [
    {
        path: '/admin/login',
        render: Login,
        unique: true,
        authRoute: true,
    },
    {
        path: '/admin/logout',
        render: Logout,
        unique: true,
        authRoute: true,
    },
    {
        path: '/admin/dashboard',
        render: HomePage
    },
    {
        path: '/admin/user/create',
        render: UserCreate
    },
    {
        path: '/admin/users',
        render: Users
    },
    {
        path: '/admin/users/edit/:id',
        render: UserEdit
    },
    {
        path: '/admin/users/type',
        render: UserTypes
    },
    {
        path: '/admin/settings/general',
        render: General
    },
    {
        path: '/admin/settings/pacotes',
        render: Pacotes
    },
    {
        path: '/admin/settings/pacotes/create',
        render: PacotesCreate
    },
    {
        path: '/admin/settings/pacotes/edit/:id',
        render: PacotesEdit
    },
    {
        path: '/admin/settings/pacotes/specs/:id',
        render: PacotesSpecs
    },
    {
        path: '/admin/settings/pacotes/specs/:id/create',
        render: PacotesSpecsCreate
    },
    {
        path: '/admin/settings/pacotes/specs/edit/:id',
        render: PacotesSpecsEdit
    },
    {
        path: '/admin/settings/payments/methods',
        render: PaymentMethods
    },
    {
        path: '/admin/settings/payments/methods/create',
        render: PaymentMethodsCreate
    },
    {
        path: '/admin/settings/payments/methods/edit/:id',
        render: PaymentMethodsEdit
    },
    {
        path: '/admin/cursos/create',
        render: CursoCreate
    },
    {
        path: '/admin/cursos',
        render: Cursos
    },
    {
        path: '/admin/cursos/view/:id',
        render: CursoView
    },
    {
        path: '/admin/cursos/edit/:id',
        render: CursoEdit
    },
    {
        path: '/admin/cursos/modules/videos/:id',
        render: Videos
    },
    {
        path: '/admin/cursos/videos',
        render: VideosList
    },
    {
        path: '/admin/cursos/modules/videos/:id/create',
        render: VideoUpload
    },
    {
        path: '/admin/cursos/modules/videos/:id/view/:video',
        render: VideoUploadView
    },
    {
        path: '/admin/cursos/modules/videos/:id/edit/:video',
        render: VideoUploadEdit
    },
    {
        path: '/admin/cursos/quiz',
        render: Quiz
    },
    {
        path: '/admin/cursos/:type/quiz/:id',
        render: Quiz
    },
    {
        path: '/admin/cursos/:type/quiz/:id/create',
        render: QuizCreate
    },
    {
        path: '/admin/cursos/:type/quiz/edit/:id',
        render: QuizEdit
    },
    {
        path: '/admin/cursos/quiz/:id/questions',
        render: QuizQuestions
    },
    {
        path: '/admin/cursos/quiz/:id/questions/create',
        render: QuizQuestionsCreate
    },
    {
        path: '/admin/cursos/quiz/:quiz/questions/edit/:id',
        render: QuizQuestionsEdit
    },
    {
        path: '/admin/cursos/quiz/question/:id/answers',
        render: QuestionsAnswers
    },
    {
        path: '/admin/cursos/quiz/questions/:id/answers/create',
        render: AnswersCreate
    },
    {
        path: '/admin/cursos/quiz/question/:qt/answers/edit/:id',
        render: AnswersEdit
    },
    {
        path: '/admin/pagamentos',
        render: Pagamentos
    },
    {
        path: '/admin/pagamentos/subscricoes',
        render: Subscricoes
    },
    {
        path: '/admin/logs/logins',
        render: LogsLogin
    },
    {
        path: '/admin/library',
        render: Library
    },
    {
        path: '/admin/library/book/create',
        render: LibraryBookCreate
    },
    {
        path: '/admin/library/book/edit/:id',
        render: LibraryBookEdit
    },
    {
        path: '/admin/library/book/view/:id',
        render: LibraryBookView
    },
    {
        path: '/admin/library/tipos',
        render: BookTipos
    },
    {
        path: '/admin/library/tipos/create',
        render: BookTiposCreate
    },
    {
        path: '/admin/library/tipos/edit/:id',
        render: BookTypesEdit
    },
    {
        path: '/admin/library/categories',
        render: BookCategories
    },
    {
        path: '/admin/library/categories/create',
        render: BookCategoriesCreate
    },
    {
        path: '/admin/library/categories/edit/:id',
        render: BookCategoriesEdit
    },
    {
        path: '/admin/cursos/categorias',
        render: CoursesTipos
    },
    {
        path: '/admin/cursos/categorias/create',
        render: CoursesTiposCreate
    },
    {
        path: '/admin/cursos/categorias/edit/:id',
        render: CoursesTiposEdit
    },
    {
        path: '/admin/podcast',
        render: PodCast
    },
    {
        path: '/admin/podcast/create',
        render: VideoPodcastUpload
    },
    {
        path: '/admin/podcast/view/:id',
        render: VideoPodcastView
    },
    {
        path: '/admin/podcast/edit/:id',
        render: VideoPodcastEdit
    },
    {
        path: '/admin/podcast/categorias',
        render: PodcastCategories
    },
    {
        path: '/admin/podcast/categorias/create',
        render: PodcastCategoriesCreate
    },
    {
        path: '/admin/podcast/categorias/edit/:id',
        render: PodcastCategoriesEdit
    },
    {
        path: '/admin/webinars',
        render: Webinars
    },
    {
        path: '/admin/webinars/create',
        render: WebinarsCreate
    },
    {
        path: '/admin/webinars/view/:id',
        render: WebinarsView
    },
    {
        path: '/admin/webinars/edit/:id',
        render: WebinarsEdit
    },
    {
        path: '/admin/settings/general/create',
        render: SlideTextCreate
    },
    {
        path: '/admin/settings/general/edit/:id',
        render: SlideTextEdit
    },
    {
        path: '/admin/tutores/create',
        render: TutoresCreate
    },
    {
        path: '/admin/tutores',
        render: Tutores
    },
    {
        path: '/admin/tutores/edit/:id',
        render: TutoresEdit
    },
    {
        path: '/admin/settings/videos',
        render: VideosLinks
    },
    {
        path: '/admin/settings/videos/create',
        render: VideosLinksCreate
    },
    {
        path: '/admin/settings/videos/edit/:id',
        render: VideosLinksEdit
    },
    {
        path: '/admin/settings/faculdades',
        render: Faculdades
    },
    {
        path: '/admin/settings/faculdades/create',
        render: FaculdadesCreate
    },
    {
        path: '/admin/settings/faculdades/edit/:id',
        render: FaculdadesEdit
    }
    
];

export default RoutesList;